import { ADMINS_TYPES,ADMIN_TYPES,USERS_TYPES, BEING_ADMIN, SUCCESS, BEING_SUPERADMIN, PRODUCTS_TYPES, TOGGLE_PRODUCT_STATUS_TYPES, BANNER_TYPES, CATEGORY_TYPES, BRAND_TYPES } from "../types";
import { finish, init, success, error, successMessage } from "../commonActions";
import { SuperadminService } from "../api/superadmin_api";
import { setCookie } from "../../utils/common";
import { CLIENT_URL } from "../../utils/config";

const superadminService = new SuperadminService();

export const getAdmins = (page,perPage,status, keyword) => async (dispatch) => {
  dispatch(init(ADMINS_TYPES.GET_ADMINS));

  const response = await superadminService.getAdmins(page,perPage,status, keyword);

  dispatch(finish(ADMINS_TYPES.GET_ADMINS));

  if (response.isSuccess) {
    dispatch(success(ADMINS_TYPES.GET_ADMINS, response.data));
  } else if (!response.isSuccess) {
    dispatch(error(response.errorMessage));
  }
};

export const getAdmin = (id) => async (dispatch) => {
  dispatch(init(ADMIN_TYPES.GET_ADMIN));

  const response = await superadminService.getAdmin(id);

  dispatch(finish(ADMIN_TYPES.GET_ADMIN));

  if (response.isSuccess) {
    dispatch(success(ADMIN_TYPES.GET_ADMIN, response.data));
  } else if (!response.isSuccess) {
    dispatch(error(response.errorMessage));
  }
};

export const getUsers = (page,perPage,status, keyword) => async (dispatch) => {
  dispatch(init(USERS_TYPES.GET_USERS));

  const response = await superadminService.getUsers(page,perPage,status, keyword);
  
  dispatch(finish(USERS_TYPES.GET_USERS));

  if (response.isSuccess) {
    dispatch(success(USERS_TYPES.GET_USERS, response.data));
  } else if (!response.isSuccess) {
    dispatch(error(response.errorMessage));
  }
};

export const getUser = (id) => async (dispatch) => {
  dispatch(init(USERS_TYPES.GET_USER));

  const response = await superadminService.getUser(id);
  
  dispatch(finish(USERS_TYPES.GET_USER));

  if (response.isSuccess) {
    dispatch(success(USERS_TYPES.GET_USER, response.data));
  } else if (!response.isSuccess) {
    dispatch(error(response.errorMessage));
  }
};

export const beAdmin = (id, history) => async (dispatch) => {

  const response = await superadminService.getAdmin(id);
  
  if (response.isSuccess) {
    dispatch(success(BEING_ADMIN, response.data));
    history.push('/')
    return true
  } else if (!response.isSuccess) {
    dispatch(error(response.errorMessage));
    return false
  }
};

export const beSuperAdmin = () => async dispatch => {
  dispatch({type:BEING_SUPERADMIN})
}

export const beUser = (id, history) => async (dispatch) => {
  const response = await superadminService.beUser(id);
  
  if (response.isSuccess) {
    setCookie("token", response.data.accessToken);
    setCookie("refresh-token", response.data.refreshToken);
    
    window.open(`${CLIENT_URL}/myprofile/manageAccount`, "_blank");
    return true
  } else if (!response.isSuccess) {
    dispatch(error(response.errorMessage));
    return false
  }
};

export const getProducts = ({ id, page, perPage, keyword, createdAt, updatedAt, status, price, outofstock }) => async (dispatch) => {
  dispatch(init(PRODUCTS_TYPES.GET_PRODUCTS));

  const response = await superadminService.getProducts({ page, perPage, keyword, createdAt, updatedAt, status, price, outofstock });

  dispatch(finish(PRODUCTS_TYPES.GET_PRODUCTS));

  if (response.isSuccess) {
    dispatch(success(PRODUCTS_TYPES.GET_PRODUCTS, response.data));
  } else if (!response.isSuccess) {
    dispatch(error(response.errorMessage));
  }
};

export const approveProduct = (product_slug) => async (dispatch) => {
  dispatch(init(TOGGLE_PRODUCT_STATUS_TYPES.TOGGLE_PRODUCT_STATUS));

  const response = await superadminService.approveProduct(product_slug);

  dispatch(finish(TOGGLE_PRODUCT_STATUS_TYPES.TOGGLE_PRODUCT_STATUS));

  if (response.isSuccess) {
    dispatch(success(TOGGLE_PRODUCT_STATUS_TYPES.TOGGLE_PRODUCT_STATUS, response.data));
  } else if (!response.isSuccess) {
    dispatch(error(response.errorMessage));
  }
};

export const disApproveProduct = (product_slug, comment) => async (dispatch) => {
  dispatch(init(TOGGLE_PRODUCT_STATUS_TYPES.TOGGLE_PRODUCT_STATUS));

  const response = await superadminService.disApproveProduct(product_slug, comment);

  dispatch(finish(TOGGLE_PRODUCT_STATUS_TYPES.TOGGLE_PRODUCT_STATUS));

  if (response.isSuccess) {
    dispatch(success(TOGGLE_PRODUCT_STATUS_TYPES.TOGGLE_PRODUCT_STATUS, response.data[1]));
  } else if (!response.isSuccess) {
    dispatch(error(response.errorMessage));
  }
};

export const productRemove = (product_slug) => async (dispatch) => {
  dispatch(init(TOGGLE_PRODUCT_STATUS_TYPES.TOGGLE_PRODUCT_STATUS));
  const response = await superadminService.productRemove(product_slug);
  dispatch(finish(TOGGLE_PRODUCT_STATUS_TYPES.TOGGLE_PRODUCT_STATUS));
  if (response.isSuccess) {
    dispatch(success(TOGGLE_PRODUCT_STATUS_TYPES.TOGGLE_PRODUCT_STATUS, response.data));
    dispatch(successMessage("Produto removido da loja!"));
    dispatch(getProducts(product_slug));
  } else if (!response.isSuccess) {
    dispatch(error(response.errorMessage));
  }
};

export const toggleProductFeatured = (product_slug) => async (dispatch) => {
  const response = await superadminService.toggleProductFeatured(product_slug);

  dispatch(finish(TOGGLE_PRODUCT_STATUS_TYPES.TOGGLE_PRODUCT_STATUS));

  if (response.isSuccess) {
    dispatch(successMessage("Destaque atualizado!"));
    dispatch(getProducts(product_slug));

  } else if (!response.isSuccess) {
    dispatch(error(response.errorMessage));
  }
};

export const createBanner = (formData) => async (dispatch) => {
  const response = await superadminService.createBanner(formData);

  if (response.isSuccess) {
    dispatch(successMessage("Banner criado com sucesso!"));
    getBanners();
  }else {
    dispatch(error(response.errorMessage));
  }
};

export const getBanners = (page,perPage,status, keyword) => async (dispatch) => {
  dispatch(init(BANNER_TYPES.GET_BANNERS));

  const response = await superadminService.getBanners(page,perPage,status, keyword);
  
  dispatch(finish(BANNER_TYPES.GET_BANNERS));

  if (response.isSuccess) {
    dispatch(success(BANNER_TYPES.GET_BANNERS, response.data));
  } else if (!response.isSuccess) {
    dispatch(error(response.errorMessage));
  }
};

export const deleteBanner = (id) => async (dispatch) => {
  dispatch(init(BANNER_TYPES.GET_BANNERS));

  const response = await superadminService.deleteBanner(id);

  dispatch(finish(BANNER_TYPES.GET_BANNERS));

  if (response.isSuccess) {
    dispatch(successMessage('Seu banner foi excluído com sucesso.'))
    dispatch(getBanners({id}));
  } else if (!response.isSuccess) {
    dispatch(error(response.errorMessage));
  }
};

export const getBrands = () => async (dispatch) => {
  dispatch(init(BRAND_TYPES.GET_BRANDS));
  
  const response = await superadminService.getBrands();

  dispatch(finish(BRAND_TYPES.GET_BRANDS));

  if (response.isSuccess) {
    dispatch(success(BRAND_TYPES.GET_BRANDS, response.data));
  } else {
    dispatch(error(response.errorMessage));
  }
};

export const putBrand = (body) => async (dispatch) => {
  dispatch(init(BRAND_TYPES.PUT_BRAND));
  const response = await superadminService.putBrand(body);

  dispatch(finish(BRAND_TYPES.PUT_BRAND));

  if (response.isSuccess) {
    dispatch(success(BRAND_TYPES.PUT_BRAND, response.data));
    dispatch(success(SUCCESS, 'Marca Adicionada ou Atualizada com sucesso.'))
    setTimeout(() => {
      window.location.href = "/superadmin/manage-brands"; 
    }, 1000);    
  } else {
    dispatch(error(response.errorMessage));
  }
};

export const getCategories = () => async (dispatch) => {
  dispatch(init(CATEGORY_TYPES.GET_CATEGORIES));

  const response = await superadminService.getCategories();
  
  dispatch(finish(CATEGORY_TYPES.GET_CATEGORIES));

  if (response.isSuccess) {
    dispatch(success(CATEGORY_TYPES.GET_CATEGORIES, response.data.categories));
  } else {
    dispatch(error(response.errorMessage));
  }
};
