import React, { useState } from "react";
import { Button } from "antd";
import ReCAPTCHA from "react-google-recaptcha";

function SigninForm({ handleChange, handleSubmit, state, loading }) {
  const [capVal, setCapVal] = useState(null);
  const [errors, setErrors] = useState({});
  
  // Estados locais para os campos de senha
  const [password, setPassword] = useState(state.password || "");
  const [passwordConfirm, setPasswordConfirm] = useState(state.passwordConfirm || "");
  
  // Estados para visibilidade da senha
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  // Validação da senha (mínimo 8 caracteres, letras, números e símbolos)
  const validatePassword = (password) => {
    return /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(password);
  };

  // Manipular mudanças no campo de senha
  const handlePasswordChange = (e) => {
    const { value } = e.target;
    setPassword(value);
    handleChange(e);

    setErrors((prevErrors) => ({
      ...prevErrors,
      password: validatePassword(value) ? "" : "A senha deve ter no mínimo 8 caracteres, incluindo letras, números e símbolos."
    }));
  };

  // Manipular mudanças no campo de confirmação de senha
  const handleConfirmPasswordChange = (e) => {
    const { value } = e.target;
    setPasswordConfirm(value);
    handleChange(e);

    setErrors((prevErrors) => ({
      ...prevErrors,
      passwordConfirm: value === password ? "" : "As senhas não coincidem."
    }));
  };

  return (
    <form className="login-form" onSubmit={handleSubmit} autoComplete="on">
      <h1 className="text-center">Alterar Senha</h1>

      {/* Campo Senha */}
      <div className="form-group form-group-pass">
        <input
          type={showPassword ? "text" : "password"}
          className="form-control"
          placeholder="Nova Senha"
          autoComplete="on"
          name="password"
          onChange={handlePasswordChange}
          value={password} // Sempre controlado
          required
        />
        <div 
          className="illustration"
          onClick={() => setShowPassword(!showPassword)}
          style={{ cursor: "pointer", color: "#ffff" }}
        >
          {showPassword ? <i className="fa fa-eye" /> : <i className="fa fa-eye-slash" />}
        </div>
      </div>
      {errors.password && <p className="error-text">{errors.password}</p>}

      {/* Campo Confirmar Senha */}
      <div className="form-group form-group-pass">
        <input
          type={showPasswordConfirm ? "text" : "password"}
          className="form-control"
          placeholder="Confirmar Senha"
          autoComplete="on"
          name="passwordConfirm"
          onChange={handleConfirmPasswordChange}
          value={passwordConfirm} // Sempre controlado
          required
        />
        <div 
          className="illustration"
          onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
          style={{ cursor: "pointer", color: "#ffff" }}
        >
          {showPasswordConfirm ? <i className="fa fa-eye" /> : <i className="fa fa-eye-slash" />}
        </div>
      </div>
      {errors.passwordConfirm && <p className="error-text">{errors.passwordConfirm}</p>}

      <div className="resert-password-container">
        <a href="/" className="resert-password-link">Login</a>
      </div>

      <div className="form-group">
        <div className="re-captcha">
          <ReCAPTCHA 
            sitekey="6LfcqZ0qAAAAAFauEZBgQ6v3axzrxRNBGCzV7SWK"
            onChange={(val) => setCapVal(val)} 
          />
        </div>
        <Button
          className="btn btn-primary btn-block"
          htmlType="submit"
          loading={loading}
          disabled={!capVal || errors.password || errors.passwordConfirm}
        >
          Alterar Senha
        </Button>
      </div>
    </form>
  );
}

export default SigninForm;
