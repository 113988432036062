import React, { useState } from 'react';
import { Form, Input, Button } from 'antd';
import { useDispatch } from 'react-redux';
// Ação do Redux (presumindo que você tenha uma ação já configurada para gerenciar marcas)
import { putBrand } from '../../../../redux/actions/superadmin_action';

const BrandForm = () => {
  const [brandName, setBrandName] = useState('');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = async (values) => {
    setLoading(true);

    const body = {
      brandName: values.brandName
    }

    await dispatch(putBrand(body));
    setLoading(false);
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div
        style={{
          width: '100%',
          maxWidth: '400px',
          padding: '20px',
        }}
      >
        <h2 style={{ textAlign: 'center' }}>
          Adicionar Marca
        </h2>
        <Form
          name="brandForm"
          onFinish={handleSubmit}
          initialValues={{ brandName }}
          layout="vertical"
        >
          <Form.Item
            label="Nome da Marca"
            name="brandName"
            rules={[{ required: true, message: 'Por favor, insira o nome da marca!' }]}
          >
            <Input
              value={brandName}
              onChange={(e) => setBrandName(e.target.value)}
              placeholder="Nome da marca"
            />
          </Form.Item>

          <Form.Item  style={{ textAlign: 'right' }}>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              disabled={loading}
              style={{ width: '140px' }}
            >
              Adicionar Marca
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default BrandForm;
