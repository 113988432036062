import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { Table as AntdProductTable, Input, Button, Space, Popconfirm, Avatar, Drawer } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import PropTypes from 'prop-types';
import ProductDetail from './ProductDetail';
import 'moment/locale/pt-br'; 
import { isEmpty } from 'lodash';
import { PlusOutlined } from "@ant-design/icons";

const ProductTable = ({ getProduct, getProducts, deleteProduct, multiLoading, products, totalCount, user }) => {
    const history = useHistory();
    moment.locale('pt-br');

    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 5,
        pageSizeOptions: [5, 10, 15, 20, 50, 100],
        showQuickJumper: true,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
    });
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const searchInput = useRef(null);

    useEffect(() => {
        if (user) {
            getProducts({ id: user._id, page: pagination.current, perPage: pagination.pageSize });
        }
    }, [user, getProducts, pagination.current, pagination.pageSize]);

    useEffect(() => {
        setPagination(prev => ({
            ...prev,
            total: totalCount
        }));
    }, [totalCount]);

    useEffect(() => {
        const slug = sessionStorage.getItem('openedSlug');
        const isRealdyOpen = sessionStorage.getItem('notificationOpenedModal') === 'true';
    
        if (slug && !isEmpty(products) && !isRealdyOpen) {
            getProduct(slug);
            setIsDrawerOpen(true);
            sessionStorage.setItem('notificationOpenedModal', 'true');
        }
    }, [products, getProduct, setIsDrawerOpen]);

    const handleProductTableChange = (pagination, filters, sorter) => {
        const { current, pageSize } = pagination;

        const priceSortOrder = sorter.columnKey === 'price' ? sorter.order : null;
        const createdAtSortOrder = sorter.columnKey === 'createdAt' ? sorter.order : null;

        const price = priceSortOrder ? (priceSortOrder === 'ascend' ? 'asc' : 'desc') : '';
        const createdAt = createdAtSortOrder ? (createdAtSortOrder === 'ascend' ? 'asc' : 'desc') : '';

        setPagination({
            ...pagination,
            current,
            pageSize
        });

        if (user) {
            getProducts({
                id: user._id,
                page: current,
                perPage: pageSize,
                keyword: filters.product?.[0],
                createdAt,
                updatedAt: '',
                status: filters.status?.[0],
                price,
                outofstock: filters.qty?.[0]
            });
        }
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
    };

    const handleReset = clearFilters => {
        clearFilters();
    };

    const getProductsearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8, backgroundColor: '#495057' }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Cancel
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : '#495057' }} />,
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.current.select(), 100);
            }
        },
        render: product => (
            <>
                {product.name}{' '}{product.images?.length && <Avatar shape="square" size='small' src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${product.images[0].thumbnail}`} />}
            </>
        )
    });

    const openProduct = (product) => {
        getProduct(product.slug);
        setIsDrawerOpen(true);
    };

    const filters = useMemo(() => {
        const baseFilters = [
            { text: 'Todos', value: 'undefined' },
            { text: 'Não verificado', value: 'unverified' },
            { text: 'Verificado', value: 'verified' },
            { text: 'Rejeitado', value: 'rejected' },
            { text: 'Deletado', value: 'deleted' },
            { text: 'Destaque', value: 'featured' },
        ];
        return user?.role === 'admin' ? baseFilters.slice(0, 4) : baseFilters;
    }, [user]);

    const isSuperadmin = useMemo(() => user?.role === 'superadmin', [user]);

    const columns = useMemo(() => [
        {
            title: 'Produto',
            dataIndex: '',
            key: 'product',
            width: '30%',
            ...getProductsearchProps('')
        },
        {
            title: 'Categoria',
            dataIndex: 'category',
            render: cat => cat.map(a => a.displayName).join(', '),
            width: '15%',
        },
        {
            title: 'Preço',
            dataIndex: '',
            sorter: { multiple: 1 },
            key: 'price',
            render: product => (
                <h4 style={{ fontSize: '1.1rem' }}>
                    {`R$ ${ parseFloat(product.price.$numberDecimal).toFixed(2).replace('.',',')}`}
                    <span className="period" style={{ fontSize: '0.7rem' }}>{`/${product.discountRate}% desconto (Pix)`}</span>
                </h4>
            ),
            width: '20%',
        },
        {
            title: 'Status',
            dataIndex: '',
            key: 'status',
            filterMultiple: false,
            filters,
            render: product => {
                if (user?.role === 'superadmin' && product.isDeleted) return (<><span className="badge badge-pill badge-dark">Deletado</span></>);
                else if (product.isRejected) return (<><span className="badge badge-pill badge-warning">Rejeitado</span></>);
                else if (product.isFeatured) return (<><span className="badge badge-pill badge-secondary">Destaque</span><span className="badge badge-pill badge-success">Verificado</span></>);
                else if (!product.isVerified) return (<span className="badge badge-pill badge-danger">Não verificado</span>);
                else if (product.isVerified) return (<span className="badge badge-pill badge-success">Verificado</span>);
            },
            width: '5%',
        },
        {
            title: 'Qtd',
            dataIndex: '',
            key: 'qty',
            filterMultiple: false,
            filters: [
                { text: 'All', value: 'undefined' },
                { text: 'Out of Stock', value: 'yes' },
            ],
            render: product => product.quantity < 1 ? (<span className="badge badge-pill badge-dark">Fora de estoque</span>) : `${product.quantity}`,
            width: '5%',
        },
        {
            title: 'Criado Em',
            dataIndex: 'createdAt',
            sorter: { multiple: 2 },
            key: 'createdAt',
            width: '10%',
            render: date => moment(date).format("DD/MM/YYYY")
        },
        {
            title: 'Ações',
            dataIndex: '',
            width: '10%',
            render: product => (
                <>
                    <button onClick={() => openProduct(product)} className="btn btn-info btn-sm"><i className="fas fa-eye"></i></button>
                    {user?.role === 'admin' && <button onClick={() => history.push(`/edit-product/${product.slug}`)} className="btn btn-warning btn-sm"><i className="fas fa-pen"></i></button>}
                    <Popconfirm
                        title="Tem certeza de que deseja excluir este produto?"
                        onConfirm={() => deleteProduct(isSuperadmin ? product.soldBy._id : product.soldBy, product.slug)}
                        okText="Sim"
                        cancelText="Não"
                    >
                        <button className="btn btn-danger btn-sm"><i className="fas fa-trash"></i></button>
                    </Popconfirm>
                </>
            ),
        },
    ], [user, deleteProduct, openProduct, filters, getProductsearchProps]);

    const addProduct = () =>{
        window.location.href = "/add-product";
    }

    return (
        <>
            { user?.role === 'admin' && 
                <div style={{ display: "flex", justifyContent: "flex-end", margin: "20px" }}>
                    <Button type="primary"  onClick={addProduct} style={{display:'flex', alignItems: 'center'}}>
                        <PlusOutlined /> Adicionar Produto
                    </Button>
                </div> 
            }
            <AntdProductTable
                columns={columns}
                rowKey={record => record._id}
                dataSource={products}
                pagination={pagination}
                loading={multiLoading}
                onChange={handleProductTableChange}
                size='small'
            />
            <Drawer
                title="Detalhes do Produto"
                placement="right"
                width={800}
                closable
                onClose={() => setIsDrawerOpen(false)}
                getContainer={false}
                open={isDrawerOpen}
                closeIcon={<i className="fas fa-times btn btn-danger"></i>}
            >
                {isDrawerOpen && <ProductDetail
                    isOrderDetailOpen={isDrawerOpen}
                    isSuperadmin={isSuperadmin}
                />}
            </Drawer>
        </>
    );
};

ProductTable.propTypes = {
    user: PropTypes.object,
    products: PropTypes.array,
    multiLoading: PropTypes.bool,
    pageCount: PropTypes.number,
    getProduct: PropTypes.func.isRequired,
    getProducts: PropTypes.func.isRequired,
    deleteProduct: PropTypes.func,
};

export default ProductTable;
