import React from 'react'
import Layout from '../../core/Layout'
import CategoryList from './CategoryList'

function index() {
  return (
    <Layout>
       <CategoryList/>
    </Layout>
  )
}

export default index
