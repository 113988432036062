import { postService, getService } from "../commonServices";

export class AuthService {
  loginUser(email, password) {
    const body = JSON.stringify({ email, password });
    let url = `/admin-auth/signin`;
    let data = postService(url, body);
    return data;
  }
  forgotPassword(email) {
    const body = JSON.stringify({ email });
    let url = `/admin-auth/forgot-password`;
    let data = postService(url, body, "PUT");
    return data;
  }
  resetPassword(body) {
    let url = `/admin-auth/reset-password`;
    let data = postService(url, body, "PUT");
    return data;
  }
  loadMe() {
    let url = `admin-auth/load-me`;
    let data = getService(url);
    return data;
  }
}
