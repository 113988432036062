import React, { useState } from "react";
import ResetPasswordForm from "./ResetPasswordForm";
import NewPasswordForm from "./NewPasswordForm";
import { connect } from "react-redux";
import { useLocation } from 'react-router-dom';
import { resetPassword, forgotPassword } from "../../../redux/actions/auth_actions";
import { useHistory } from "react-router-dom";

const ResertPassaword = (props) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const history = useHistory()

  const [state, setState] = useState({
    email: "",
    password: "",
    error: "",
  });

  const { error, email, password } = state;

  const {loading} = props;

  const handleChange = (event) => {
    setState({
      ...state,
      error: false,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    props.forgotPassword(email, history);
  };

  const handleSubmitPassword = (event) => {
    event.preventDefault();

    let body = {
      newPassword: password,
      resetPasswordLink: token
    }

    props.resetPassword(body, history);
  };

  const showError = () => <div className="alert alert-danger">{error}</div>;

  // let tokenVerfied = false;  
  // if (token) {
  //     try{
  //         tokenVerfied = jwt.verify(token, JWT_EMAIL_VERIFICATION_KEY);
  //     } catch(error){
  //         if(error.name === "TokenExpiredError"){
  //             alert('Reset link has expired')
  //             window.location.href = "/";
  //         }else{
  //             alert('Invalid Link') 
  //             window.location.href = "/";
  //         }
  //     }
  // }

  return (
    <div className="login-dark">
      {error && showError()}
        {
          !token ? (
          <ResetPasswordForm
            handleSubmit={handleSubmit}
            handleChange={handleChange}
            state={state}
            //loading={loading}
          />):(<>
          <NewPasswordForm
            handleSubmit={handleSubmitPassword}
            handleChange={handleChange}
            state={state}
            //loading={loading}
          />
          </>)
        }
         
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuth,
    loading: state.auth.loading
  };
};

export default connect(mapStateToProps, { resetPassword, forgotPassword })(ResertPassaword);
