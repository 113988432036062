import React from 'react'
import Layout from '../../../core/Layout'
import BrandForm from './BrandForm'

function index() {
  return (
    <Layout>
      <BrandForm />
    </Layout>
  )
}

export default index
