import React from 'react'
import moment from 'moment'
import { Carousel, Avatar } from 'antd';
import { SideBySideMagnifier} from "react-image-magnifiers";
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import OrderStatus from './OrderStatus';
import OrderCancel from './OrderCancel';
import NotaFiscalUploader from './NotaFiscalUploader';
import 'moment/locale/pt-br'; 

const OrderDetail = ({ order ,singleLoading ,  isOrderDetailOpen }) => {
    function onChange(a, b, c) {
        // console.log(a, b, c);
    }
    moment.locale('pt-br');

    const contentStyle = {
        height: '170px',
        color: '#fff',
        lineHeight: '160px',
        textAlign: 'center',
        background: '#364d79',
    };

    return (
            <div className="row">
                <div className="col-xl-8">
                    <div className="card">
                        <div className="card-header">
                            {/* <h5 className="card-title mb-0">Clients</h5> */}
                        </div>
                        <div className="card-body ">
                            <div className="mb-2">
                                <h6>Detalhes do Pedido</h6>
                            </div>
                            <div className="row mb-4">
                                <div className="col-md-6">
                                    
                                <Carousel afterChange={onChange}>
                                {
                                    order?.product?.images.map(image=> (

                                    <div key={image._id}>
                                        <h3 style={contentStyle}>
                                            <SideBySideMagnifier
                                                style={{ height: '100%', width: '100%' }}
                                                alwaysInPlace={true}
                                                imageSrc={`${process.env.REACT_APP_IMAGE_BASE_URL}/${image.medium}`}
                                                imageAlt="Example"
                                                // largeImageSrc={`${process.env.REACT_APP_IMAGE_BASE_URL}/${image.large}`} // Optional
                                            />
                                         {/* <img style={{height:'100%',width:'100%'}} src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${image.medium}`}/> */}
                                         </h3>
                                    </div>
                                    ))
                                }
                                </Carousel>
                                </div>
                                <div className="col-md-6 text-md-right">
                                    <div className="text-muted">Nome</div>
                                <strong>{order?.product?.name}</strong>
                                <div className="text-muted">Qtd</div>
                                <strong>{order?.product?.quantity}</strong>
                                </div>
                            </div>
                            <hr className="my-4" />
                            <div className="mb-2">
                                <h6>Detalhes do Pedido</h6>
                            </div>
                            <div className="row mb-4">
                                <div className="col-md-12 d-flex justify-content-between align-items-center">
                                    <div><strong>Código do Pedido</strong></div>
                                    <div>{order?.orderCod}</div>
                                </div>
                                <div className="col-md-12 d-flex justify-content-between align-items-center">
                                    <div><strong>Status</strong></div>
                                    <div><OrderStatus isOrderDetailOpen={isOrderDetailOpen} shippingMethod={order?.shippingMethod} status={order?.status?.currentStatus} order_id={order?._id} admin_id={order?.soldBy} remainingProductQty={order?.product?.quantity-order?.quantity} /></div>
                                </div>
                                <div className="col-md-12 d-flex justify-content-between align-items-center">
                                    <div><strong>Quantidade</strong></div>
                                    <div>{order?.quantity}</div>
                                </div>
                                <div className="col-md-12 d-flex justify-content-between align-items-center">
                                    <div><strong>Método de Envio</strong></div>
                                    <div>{order?.shippingMethod == 'delivery'? 'Entrega':'Retirar na Loja'}</div>
                                </div>
                                <div className="col-md-12 d-flex justify-content-between align-items-center">
                                    <div><strong>Criado em</strong></div>
                                    <div>{order && moment(order?.createdAt).format("dddd, DD/MM/YYYY, HH:mm")}</div>
                                </div>
                            </div>
                            <hr className="my-4" />
                            <div className="mb-2">
                                <h6>Detalhes do Pagamento</h6>
                            </div>
                            <div className="row mb-10">
                                <div className="col-md-6">
                                <strong>

                                    <p>
                                    Código do Mercado Pago<br />Está Pago <br/> Método <br /> Frete <br /> Preço <br /> Desconto <br /> Valor Pago <br />
                                    </p>
                                </strong>
                                </div>
                                <div className="col-md-6 text-md-right">
                                    <p>
                                        {order?.payment?.mercadoPagoId || 'Sem Código'}
                                        <br />
                                        {/* {order?.payment?.transactionCode}
                                        <br /> */}
                                        {
                                            order && (order?.isPaid ? <span className="badge badge-pill badge-success">Sim</span> :
                                                <span className="badge badge-pill badge-danger">Não</span>)
                                        }
                                        <br /> {order?.payment?.method === 'pix' ? 'Pix':'Cartão de Crédito'}
                                        <br/> R$ {order?.shippingCharge.replace('.',',')} 
                                        <br/> R$ {parseFloat(order?.price.$numberDecimal.toString() * order?.quantity).toFixed(2).replace('.',',')}
                                        <br/> R$ {order?.discontOrder.replace('.',',')} 
                                        <br/> R$ {order?.amountPaid.replace('.',',')} <br />
                                    </p>
                                </div>
                            </div>
                            <hr className="my-4" />
                            <div className="mb-2">
                                <h6>Detalhes do usuário</h6>
                            </div>
                            <div className="row mb-4">
                                <div className="col-md-12 d-flex justify-content-between align-items-center">
                                    <div><strong>Nome</strong></div>
                                    <div>{order?.user?.name}</div>
                                </div>
                                <div className="col-md-12 d-flex justify-content-between align-items-center">
                                    <div><strong>Email</strong></div>
                                    <div>{order?.user?.email}</div>
                                </div>
                                <div className="col-md-12 d-flex justify-content-between align-items-center">
                                    <div><strong>Tipo de Pessoa</strong></div>
                                    <div>{order?.user?.personType}</div>
                                </div>
                                <div className="col-md-12 d-flex justify-content-between align-items-center">
                                    <div><strong>CPF/CNPJ</strong></div>
                                    <div>{order?.user?.personNumber}</div>
                                </div>
                                <div className="col-md-12 d-flex justify-content-between align-items-center">
                                    <div><strong>Contato</strong></div>
                                    <div>{order?.user?.phoneNumber}</div>
                                </div>
                                <div className="col-md-12 d-flex justify-content-between align-items-center">
                                    <div><strong>Nascimento</strong></div>
                                    <div>{moment(order?.user?.birthDate).format("DD/MM/YYYY") }</div>
                                </div>
                                {order?.user?.gender && (
                                    <div className="col-md-12 d-flex justify-content-between align-items-center">
                                        <div><strong>Gênero</strong></div>
                                        <div>{order?.user?.gender === 'male' ? 'Masculino':'Feminino'}</div>
                                    </div>
                                )}
                                <div className="col-md-12 d-flex justify-content-between align-items-center">
                                    <div><strong>Cadastrou em</strong></div>
                                    <div>{moment(order?.user?.createdAt).format("DD/MM/YYYY H:m") }</div>
                                </div>
                                <div className="col-md-12 d-flex justify-content-between align-items-center">
                                    <div><strong>Foto</strong></div>
                                    <div>{<Avatar shape="square" size='medium' src={order?.user.photo ? `${process.env.REACT_APP_IMAGE_BASE_URL}/${order?.user.photo}` : `${order?.user.socialPhoto}`}/>}</div>
                                </div>
                            </div>
                            <hr className="my-4" />
                            <div className="mb-2">
                                <h6>{order?.shippingMethod == 'delivery'? 'Endereço de entrega':'Endereço do cliente'}</h6>
                            </div>
                            <div className="row mb-4">
                                <div className="col-md-12 d-flex justify-content-between align-items-center">
                                    <div>{order?.shipto?.address}, N° {order?.shipto?.number}, {order?.shipto?.area}, {order?.shipto?.city}, {order?.shipto?.region}, {order?.shipto?.cep}</div>
                                </div>
                            </div>
                            <hr className="my-4" />
                            <div className="mb-2">
                                <h6>Endereço do Deposito</h6>
                            </div>
                            <div className="row mb-4">
                                <div className="col-md-12 d-flex justify-content-between align-items-center">
                                    <div>{order?.shipfrom?.address}, N° {order?.shipfrom?.number}, {order?.shipfrom?.area}, {order?.shipfrom?.city}, {order?.shipfrom?.region}, {order?.shipfrom?.cep}</div>
                                </div>
                            </div>
                            {order?.isPaid &&
                                <>
                                    <hr className="my-4" />
                                    <div className="mb-2">
                                        <h6>Documentos</h6>
                                    </div>
                                    <div className="row mb-4">
                                        <div className="col-md-12 d-flex justify-content-between align-items-center">
                                            <NotaFiscalUploader order={order}/>
                                        </div>
                                    </div>
                                </> 
                            }
                        </div>
                    </div>
                </div>
                <div className="col-xl-4">
                    <div className="card">
                        <div className="card-body">
                            <strong>Status do Pedido</strong>
                            <ul className="timeline mt-2 mb-0">
                                {
                                    order?.status?.activeDate && <li className="timeline-item">
                                        <strong>A Pagar</strong>
                                        <span className="float-right text-muted text-sm">{moment(order?.status?.activeDate).fromNow()}</span>
                                        <p></p>
                                    </li>
                                }
                                {
                                    order?.status?.paidDate && <li className="timeline-item">
                                        <strong>Pago</strong>
                                        <span className="float-right text-muted text-sm">{moment(order?.status?.paidDate).fromNow()}</span>
                                        <p></p>
                                    </li>
                                }
                                {
                                    order?.status?.approvedDate && <li className="timeline-item">
                                        <strong>Aprovado</strong>
                                        <span className="float-right text-muted text-sm">{moment(order?.status?.approvedDate).fromNow()}</span>
                                        <p></p>
                                    </li>
                                }
                                {
                                    order?.status?.dispatchedDetail?.dispatchedDate && <li className="timeline-item">
                                        <strong>Enviado</strong>
                                        <span className="float-right text-muted text-sm">{moment(order?.status?.dispatchedDetail?.dispatchedDate).fromNow()}</span>
                                        <small>
                                            <p>Este pedido foi enviado por {order?.status?.dispatchedDetail?.dispatchedBy?.name || 'Correios'}<br />
                                                <strong>Rastreio: </strong> {order?.status?.dispatchedDetail?.tracking}<br/>
                                            </p>
                                        </small>
                                    </li>
                                }
                                {
                                    order?.status?.completedDate && <li className="timeline-item">
                                        <strong>Finalizado</strong>
                                        <span className="float-right text-muted text-sm">{moment(order?.status?.completedDate).fromNow()}</span>
                                        <p></p>
                                    </li>
                                }
                                {
                                    order?.status?.tobeReturnedDetail.tobereturnedDate && <li className="timeline-item">
                                        <strong>A Ser Retornado</strong>
                                        <span className="float-right text-muted text-sm">{moment(order?.status?.tobeReturnedDetail.tobereturnedDate).fromNow()}</span>
                                        <small>
                                            <strong>Motivo: </strong> {order?.status?.tobeReturnedDetail?.remark?.comment}<br />
                                        </small>
                                        <p></p>
                                    </li>
                                }
                                {
                                    order?.status?.returnedDetail?.returnedDate && <li className="timeline-item">
                                        <strong>Devolvido</strong>
                                        <span className="float-right text-muted text-sm">{moment(order?.status?.returnedDetail?.returnedDate).fromNow()}</span>
                                        <small>
                                            <p>Este Pedido é Devolvido Por {order?.status?.returnedDetail?.returneddBy?.name || 'Correios'}<br />
                                            <strong>Motivo: </strong> {order?.status?.returnedDetail?.remark?.comment}<br />
                                            </p>
                                        </small>
                                    </li>
                                }
                                {
                                    order?.status?.cancelledDetail?.cancelledDate && <li className="timeline-item">
                                        <strong>Cancelado</strong>
                                        <span className="float-right text-muted text-sm">{moment(order?.status?.cancelledDetail?.cancelledDate).fromNow()}</span>
                                        <small> <p>Este pedido foi cancelado por {order?.status?.cancelledDetail?.cancelledBy || 'Sistema'}.<br />
                                            <strong>Motivo: </strong>{order?.status?.cancelledDetail?.remark?.comment}</p>
                                        </small>
                                    </li>
                                }
                            </ul>
                        </div>
                    </div>
                {
                    order && (order.status.currentStatus==='active' || order.status.currentStatus==='paid') && <OrderCancel isOrderDetailOpen={isOrderDetailOpen} order_id={order?._id} admin_id={order?.soldBy} />
                }
                </div>
                
            </div>
    )
}

OrderDetail.propTypes = {
    order: PropTypes.object,
    singleLoading: PropTypes.bool,
}
const mapStateToProps = (state) => ({
    order: state.order.order,
    singleLoading: state.order.singleLoading
})

export default connect(mapStateToProps,{})(React.memo(OrderDetail))

