import { ADMINS_TYPES, ADMIN_TYPES, USERS_TYPES, BANNER_TYPES, BRAND_TYPES, CATEGORY_TYPES } from "../types";

const initialState = {
    admin: null,
    admins: [],
    multiAdminLoading: true,
    singleAdminLoading: true,
    totalCount: 0,
    users: [], // Adicionado para armazenar usuários
    user: null, // Adicionado para armazenar um único usuário
    categories: [],
    category: null,
    brands: [],
    brand: null,
    loading: false, // Adicionado para indicar carregamento
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case ADMINS_TYPES.GET_ADMINS:
            return {
                ...state,
                admins: payload.admins,
                totalCount: payload.totalCount,
                multiAdminLoading: false, // Atualizando estado de carregamento
            };
        case ADMIN_TYPES.GET_ADMIN:
            return {
                ...state,
                admin: payload,
                singleAdminLoading: false,
            };
        case ADMINS_TYPES.GET_ADMINS_INIT:
            return {
                ...state,
                multiAdminLoading: true,
            };
        case ADMINS_TYPES.GET_ADMINS_FINISH:
            return {
                ...state,
                multiAdminLoading: false,
            };
        case ADMIN_TYPES.GET_ADMIN_FINISH:
            return {
                ...state,
                singleAdminLoading: false,
            };
        case ADMIN_TYPES.GET_ADMIN_INIT:
            return {
                ...state,
                singleAdminLoading: true,
                admin: null,
            };
        case USERS_TYPES.GET_USERS:
            return {
                ...state,
                users: payload.users,
                totalCount: payload.totalCount,
            };
        case USERS_TYPES.GET_USER:
            return {
                ...state,
                user: payload,
            };
        case BANNER_TYPES.GET_BANNERS:
            return {
                ...state,
                banners: payload.banners,
                totalCount: payload.totalCount,
            };
        case BANNER_TYPES.GET_BANNER:
            return {
                ...state,
                banner: payload,
            };
        case BRAND_TYPES.GET_BRANDS:
            return {
                ...state,
                brands: payload,
                totalCount: payload.totalCount,
            };
        case BRAND_TYPES.PUT_BRAND:
            return {
                ...state,
                brand: payload,
            };
        case BRAND_TYPES.GET_BRAND:
            return {
                ...state,
                brand: payload,
            };
        case CATEGORY_TYPES.GET_CATEGORIES:
            return {
                ...state,
                categories: payload,
                totalCount: payload.totalCount,
            };
        case CATEGORY_TYPES.GET_CATEGORY:
            return {
                ...state,
                category: payload,
            };
        default:
            return state;
    }
}
