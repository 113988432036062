import React, { useEffect, useState, useMemo, useRef } from 'react';
import { Table, Input, Button, Space, Spin } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getBrands } from '../../../redux/actions/superadmin_action';
import { PlusOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

const BrandList = ({ brands = [], loading, getBrands }) => {
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const history = useHistory();

    useEffect(() => {
        getBrands();
    }, [getBrands]);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Buscar ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Buscar
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Resetar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    });

    const columns = useMemo(() => [
        {
          title: 'Marca',
          dataIndex: 'brandName',
          key: 'brandName',
          ...getColumnSearchProps('brandName')
        },
        {
          title: 'Slug',
          dataIndex: 'slug',
          key: 'slug',
          ...getColumnSearchProps('slug')
        },
        {
          title: 'ID',
          dataIndex: '_id',
          key: '_id',
        },
        {
          title: 'System Name',
          dataIndex: 'systemName',
          key: 'systemName',
        },
        {
          title: 'Ações',
          dataIndex: '',
          key: 'actions',
          width: '10%',
          render: (brands) => (
              <button
                  onClick={() => history.push(`/superadmin/edit-brand/${brands.slug}`)}
                  className="btn btn-warning btn-sm"
              >
                  <i className="fas fa-pen"/> Editar
              </button>
          ),
        },
        
    ], []);

    const addBrand = () =>{
      window.location.href = "/superadmin/add-brand";
    }
    
    return (
        <Spin spinning={loading}>
            <div style={{ display: "flex", justifyContent: "flex-end", margin: "20px" }}>
                <Button type="primary"  onClick={addBrand} style={{display:'flex', alignItems: 'center'}}>
                    <PlusOutlined /> Adicionar Marca
                </Button>
            </div>
            <Table
                columns={columns}
                dataSource={brands}
                rowKey="_id"
                pagination={{ pageSize: 10 }}
            />
        </Spin>
    );
};

BrandList.propTypes = {
    brands: PropTypes.array,
    loading: PropTypes.bool,
    getBrands: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    brands: state.superadmin?.brands || [],
    loading: state.superadmin?.loading || false,
});

export default connect(mapStateToProps, { getBrands })(BrandList);
