import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Spin } from 'antd'; // Importe o Spin

const UserDetail = ({ user }) => {
    if (!user) {
        return (
            <div className="loading-container">
                <Spin size="large" tip="Carregando..." /> {/* Exibe o spinner enquanto carrega */}
            </div>
        );
    }

    return (
        <div className="card p-4">
            <h2 className="text-center text-primary">Detalhes do Usuário</h2>
            <div className="card-body">
                <p><strong>Nome:</strong> {user.name}</p>
                <p><strong>Nome da Empresa:</strong> {user.nameCompany || 'Não informado'}</p>
                <p><strong>Email:</strong> {user.email}</p>
                <p><strong>Data de Nascimento:</strong> {user.birthDate ? new Date(user.birthDate).toLocaleDateString() : 'Não informado'}</p>
                <p><strong>Telefone:</strong> {user.phoneNumber}</p>
                <p><strong>CPF/CNPJ:</strong> {user.personNumber || 'Não informado'}</p>
                <p><strong>Tipo de Pessoa:</strong> {user.personType || 'Não informado'}</p>
                <p><strong>Gênero:</strong> {user.gender || 'Não informado'}</p>

                {/* Foto do usuário */}
                <p><strong>Foto de Perfil:</strong></p>
                {user.photo ? (
                    <img src={user.photo} alt="Foto de Perfil" style={{ width: '150px', height: '150px', borderRadius: '50%' }} />
                ) : (
                    <p>Não informada</p>
                )}

                <p><strong>Foto Social:</strong> {user.socialPhoto ? <img src={user.socialPhoto} alt="Foto Social" style={{ width: '100px', height: '100px' }} /> : 'Não informado'}</p>
                <p><strong>Status do Usuário:</strong> {user.isBlocked ? 'Bloqueado' : 'Ativo'}</p>
                <p><strong>Status de Atualização de Pedidos:</strong> {user.orderStatusUpdates ? 'Ativado' : 'Desativado'}</p>
                <p><strong>Status de Atualização de Ofertas Especiais:</strong> {user.specialOfferUpdates ? 'Ativado' : 'Desativado'}</p>
                <p><strong>Estágio de Registro:</strong> {user.registrationStage}</p>
                <p><strong>Login via:</strong> {user.loginDomain}</p>
                <p><strong>Link para redefinir senha:</strong> {user.resetPasswordLink || 'Não disponível'}</p>
                <p><strong>Link para verificação de email:</strong> {user.emailVerifyLink || 'Não disponível'}</p>
            </div>
        </div>
    );
};

UserDetail.propTypes = {
    user: PropTypes.object, // Mantendo como você pediu
};

const mapStateToProps = (state) => ({
    user: state.superadmin.user,
});

export default connect(mapStateToProps)(UserDetail);
