import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateAdminWarehouse, getAdminWarehouse } from '../../../redux/actions/profile_actions';
import { message, Form, Input, Button, Tooltip, Col, Row } from 'antd';
import InputMask from 'react-input-mask';

const estados = [
  { uf: 'AC', nome: 'Acre' },
  { uf: 'AL', nome: 'Alagoas' },
  { uf: 'AP', nome: 'Amapá' },
  { uf: 'AM', nome: 'Amazonas' },
  { uf: 'BA', nome: 'Bahia' },
  { uf: 'CE', nome: 'Ceará' },
  { uf: 'DF', nome: 'Distrito Federal' },
  { uf: 'ES', nome: 'Espírito Santo' },
  { uf: 'GO', nome: 'Goiás' },
  { uf: 'MA', nome: 'Maranhão' },
  { uf: 'MT', nome: 'Mato Grosso' },
  { uf: 'MS', nome: 'Mato Grosso do Sul' },
  { uf: 'MG', nome: 'Minas Gerais' },
  { uf: 'PA', nome: 'Pará' },
  { uf: 'PB', nome: 'Paraíba' },
  { uf: 'PR', nome: 'Paraná' },
  { uf: 'PE', nome: 'Pernambuco' },
  { uf: 'PI', nome: 'Piauí' },
  { uf: 'RJ', nome: 'Rio de Janeiro' },
  { uf: 'RN', nome: 'Rio Grande do Norte' },
  { uf: 'RS', nome: 'Rio Grande do Sul' },
  { uf: 'RO', nome: 'Rondônia' },
  { uf: 'RR', nome: 'Roraima' },
  { uf: 'SC', nome: 'Santa Catarina' },
  { uf: 'SP', nome: 'São Paulo' },
  { uf: 'SE', nome: 'Sergipe' },
  { uf: 'TO', nome: 'Tocantins' },
];

const WarehouseForm = ({ warehouse, updateAdminWarehouse, getAdminWarehouse, user }) => {
  const [form] = Form.useForm();
  const [isDisable, setIsDisabled] = React.useState(false);

  useEffect(() => {
    if (user?._id) {
      getAdminWarehouse(user._id);
    }
  }, [user, getAdminWarehouse]);

  useEffect(() => {
    if (warehouse) {
      const stateName = estados.find((estado) => estado.nome === warehouse.region)?.nome || warehouse.region;
      form.setFieldsValue({
        ownerName: warehouse.ownerName || '',
        region: stateName || '',
        city: warehouse.city || '',
        area: warehouse.area || '',
        address: warehouse.address || '',
        number: warehouse.number || '',
        cep: warehouse.cep || '',
        complement: warehouse.complement || '',
        phoneNumber: warehouse.phoneNumber || '',
        customerServiceHours: warehouse.customerServiceHours || '',
      });
    }
  }, [warehouse, form]);

  const fetchAddressByCep = async (cep) => {
    try {
      const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
      const data = await response.json();
      if (data.erro) {
        message.error('CEP inválido.');
        return;
      }
      const stateName = estados.find((estado) => estado.uf === data.uf)?.nome || data.uf;
      form.setFieldsValue({
        address: data.logradouro || '',
        city: data.localidade || '',
        region: stateName || '',
        area: data.bairro || '',
      });
    } catch (error) {
      message.error('Erro ao buscar informações do CEP.');
    }
  };

  const onSubmit = async (values) => {
    setIsDisabled(true);
    try {
      await updateAdminWarehouse(values, user._id);
      //message.success('Informações do armazém atualizadas com sucesso!');
    } catch (error) {
      //message.error('Erro ao atualizar as informações.');
    }
    setIsDisabled(false);
  };

  return (
    <div className="col-md-12">
      <div className="card">
        <div className="card-header">
          <h5 className="card-title">Endereço do Armazém</h5>
          <h6 className="card-subtitle text-muted">Deve ser o endereço da origem e do retorno do produto.</h6>
        </div>
        <div className="card-body">
          <Form form={form} layout="vertical" onFinish={onSubmit}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="ownerName"
                  label="Nome do Responsável"
                  rules={[{ required: true, message: 'Por favor, insira o nome do responsável!' }]}
                >
                  <Input placeholder="Nome do responsável" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="cep"
                  label={
                    <>
                     CEP 
                     <Tooltip title="Clique para consultar Cep no site dos Correios">
                        <a href="https://buscacepinter.correios.com.br/app/endereco/index.php" target="_blank" rel="noopener noreferrer">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#ADD8E6" width="18" height="18" style={{ marginLeft: '5px' }}>
                            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm1.07-7.75-.9.92C12.45 12.9 12.25 13.5 12.25 14h-2v-.5c0-1 .45-1.92 1.17-2.58l1.24-1.16c.37-.34.59-.83.59-1.34 0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z" />
                          </svg>
                        </a>
                      </Tooltip>
                    </>
                    
                  }
                  rules={[{ required: true, message: 'Por favor, insira o CEP!' }]}
                >
                  <InputMask
                    mask="99999-999"
                    onChange={(e) => {
                      const value = e.target.value;
                      form.setFieldsValue({ cep: value });
                      if (value.replace(/\D/g, '').length === 8) {
                        fetchAddressByCep(value.replace(/\D/g, ''));
                      }
                    }}
                  >
                    {(inputProps) => <Input {...inputProps} />}
                  </InputMask>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="region" label="Estado">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="city" label="Cidade">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="area" label="Bairro">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="address" label="Endereço">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="number"
                  label="Número"
                  rules={[{ required: true, message: 'Por favor, insira o número!' }]}
                >
                  <Input placeholder="Número" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="complement" label="Complemento">
                  <Input placeholder="Complemento" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="phoneNumber"
                  label="Número de Telefone"
                  rules={[{ required: true, message: 'Por favor, insira o número de telefone!' }]}
                >
                  <InputMask mask="(99) 99999-9999">
                    {(inputProps) => <Input {...inputProps} />}
                  </InputMask>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="customerServiceHours"
                  label="Horário de Atendimento"
                  rules={[{ required: true, message: 'Por favor, insira o horário de Atendimento!' }]}
                >
                  <Input placeholder="Segunda a Sexta: 8h às 18h | Sábado: 8h às 12h" />
                </Form.Item>
              </Col>
            </Row>
            <Button type="primary" htmlType="submit" disabled={isDisable}>
              Enviar
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
};

WarehouseForm.propTypes = {
  warehouse: PropTypes.object,
  updateAdminWarehouse: PropTypes.func.isRequired,
  getAdminWarehouse: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  warehouse: state.profile.warehouse,
});

export default connect(mapStateToProps, { updateAdminWarehouse, getAdminWarehouse })(WarehouseForm);

/*import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateAdminWarehouse, getAdminWarehouse, createFileWarehouse } from '../../../redux/actions/profile_actions';
import { message, Form, Input, Button } from 'antd';

const WarehouseForm = ({ warehouse, updateAdminWarehouse, getAdminWarehouse, createFileWarehouse, user }) => {
    const [adminWarehouse, setAdminWarehouse] = useState({
        ownerName: '',
        address: '',
        city: '',
        phoneNumber: '',
        proofOfAddress: null,
    });
    const [isDisable, setIsDisabled] = useState(false);
    const [fileName, setFileName] = useState('');

    useEffect(() => {
        getAdminWarehouse(user._id);
    }, [user]);

    useEffect(() => {
        setAdminWarehouse({
            ...adminWarehouse,
            ownerName: warehouse?.ownerName || '',
            address: warehouse?.address || '',
            city: warehouse?.city || '',
            phoneNumber: warehouse?.phoneNumber || '',
            proofOfAddress: null,
        });
    }, [warehouse]);

    const { ownerName, address, city, phoneNumber, proofOfAddress } = adminWarehouse;

    const onChange = (e) =>
        setAdminWarehouse({
            ...adminWarehouse,
            [e.target.name]: e.target.type === 'file' ? e.target.files[0] : e.target.value,
        });

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile !== undefined) {
            setFileName(selectedFile.name);
            setAdminWarehouse({
                ...adminWarehouse,
                proofOfAddress: selectedFile,
            });
        } else {
            setFileName(null);
            setAdminWarehouse({
                ...adminWarehouse,
                proofOfAddress: null,
            });
        }
    };

    const onSubmit = async (e) => {
        e.preventDefault();

        if (!adminWarehouse.proofOfAddress) {
            message.error('Insira a imagem do comprovante de endereço!');
            return;
        }

        const isJpgOrPng = adminWarehouse.proofOfAddress.type === 'image/jpeg' || adminWarehouse.proofOfAddress.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('Você só pode enviar arquivos JPG/PNG!');
            return;
        }

        setIsDisabled(true);

        try {
            const formData = new FormData();
            formData.append('doc', adminWarehouse.proofOfAddress);
            const result = await createFileWarehouse(formData, user._id);

            console.log(result)
            if (result !== undefined) {
                adminWarehouse.proofOfAddress = result.data.proofOfAddress._id;
                const body = JSON.stringify(adminWarehouse);
                await updateAdminWarehouse(body, user._id);
            }
        } catch (error) {
            message.error(error);
        }

        setIsDisabled(false);
    };

    return (
        <div className="col-md-12">
            <div className="card">
                <div className="card-header">
                    <h5 className="card-title">Endereço do Armazém</h5>
                    <h6 className="card-subtitle text-muted">Deve ser o endereço de despacho/retorno.</h6>
                </div>
                <div className="card-body">
                    <form onSubmit={onSubmit}>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="ownerName">Nome do Proprietário</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="ownerName"
                                    placeholder="Nome do proprietário"
                                    name="ownerName"
                                    value={ownerName}
                                    onChange={onChange}
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="address">Endereço</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="address"
                                    placeholder="Endereço"
                                    name="address"
                                    value={address}
                                    onChange={onChange}
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="city">Cidade</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="city"
                                    placeholder="Cidade"
                                    name="city"
                                    value={city}
                                    onChange={onChange}
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="phoneNumber">Número de Telefone</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="phoneNumber"
                                    placeholder="Número de telefone"
                                    name="phoneNumber"
                                    value={phoneNumber}
                                    onChange={onChange}
                                />
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="proofOfAddress">Comprovante de Endereço</label>
                                <input
                                    type="file"
                                    className="form-control-file"
                                    id="proofOfAddress"
                                    name="proofOfAddress"
                                    onChange={handleFileChange}
                                />
                            </div>
                        </div>
                        <button type="submit" className="btn btn-primary" disabled={isDisable}>
                            Enviar
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

WarehouseForm.propTypes = {
    warehouse: PropTypes.object,
    updateAdminWarehouse: PropTypes.func.isRequired,
    createFileWarehouse: PropTypes.func.isRequired,
    getAdminWarehouse: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    warehouse: state.profile.warehouse,
});

export default connect(mapStateToProps, { updateAdminWarehouse, getAdminWarehouse, createFileWarehouse })(WarehouseForm);
*/
// import React from 'react'
// import PropTypes from 'prop-types'



// const WarehouseForm = props => {
   
//     return (
//         <div className="col-md-12">
//             <div className="card">
//                 <div className="card-header">
//                     <h5 className="card-title">Warehouse Address</h5>
//                     <h6 className="card-subtitle text-muted">It should be dispatcher/return address.</h6>
//                 </div>
//                 <div className="card-body">
//                     <form>
//                         <div className="form-row">
//                             <div className="form-group col-md-6">
//                                 <label htmlFor="inputEmail4">Owner Name</label>
//                                 <input type="text" className="form-control" id="inputEmail4" placeholder="name" />
//                             </div>
//                             <div className="form-group col-md-6">
//                                 <label htmlFor="add">Adderss</label>
//                                 <input type="text" className="form-control" id="add" placeholder="adderss" />
//                             </div>
//                             <div className="form-group col-md-6">
//                                 <label htmlFor="dist">City</label>
//                                 <input type="text" className="form-control" id="dist" placeholder="city" />
//                             </div>
//                             <div className="form-group col-md-6">
//                                 <label htmlFor="inputPassword4">Phone Number</label>
//                                 <input type="text" className="form-control" id="inputPassword4" placeholder="Phone number" />
//                             </div>
//                         </div>
//                         <button type="submit" className="btn btn-primary">Submit</button>
//                     </form>
//                 </div>
//             </div>
//         </div>

//     )
// }

// WarehouseForm.propTypes = {

// }

// export default WarehouseForm
