import React, { useState } from 'react';
import { Form, Input, Button, notification, Select } from 'antd';
import { useDispatch } from 'react-redux';
import axios from 'axios';

// Importação opcional de ações Redux
// import { addCategory, updateCategory } from '../../redux/actions/categoryActions';

const { Option } = Select;

const CategoryForm = ({ categorySlug, initialDisplayName, initialParentId, parentCategories = [] }) => {
  const [displayName, setDisplayName] = useState(initialDisplayName || '');
  const [parentId, setParentId] = useState(initialParentId || '');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = async (values) => {
    setLoading(true);

    try {
      const response = categorySlug
        ? await axios.put(`/api/exports/category`, {
            displayName: values.displayName,
            parent_id: values.parent_id,
            category_slug: categorySlug,
          })
        : await axios.post(`/api/exports/category`, {
            displayName: values.displayName,
            parent_id: values.parent_id,
          });

      // Disparar ação Redux (se aplicável)
      if (categorySlug) {
        // dispatch(updateCategory(response.data));
      } else {
        // dispatch(addCategory(response.data));
      }

      notification.success({
        message: 'Sucesso',
        description: `Categoria ${categorySlug ? 'atualizada' : 'criada'} com sucesso!`,
      });

      setDisplayName('');
      setParentId('');
    } catch (error) {
      notification.error({
        message: 'Erro',
        description: error.response?.data?.error || 'Ocorreu um erro inesperado',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div
        style={{
          width: '100%',
          maxWidth: '400px',
          padding: '20px',
        }}
      >
        <h2 style={{ textAlign: 'center' }}>
          {categorySlug ? 'Atualizar Categoria' : 'Adicionar Categoria'}
        </h2>
        <Form
          name="categoryForm"
          onFinish={handleSubmit}
          initialValues={{ displayName, parent_id: parentId }}
          layout="vertical"
        >
          <Form.Item
            label="Nome da Categoria"
            name="displayName"
            rules={[{ required: true, message: 'Por favor, insira o nome da categoria!' }]}
          >
            <Input
              value={displayName}
              onChange={(e) => setDisplayName(e.target.value)}
              placeholder="Nome da categoria"
            />
          </Form.Item>

          <Form.Item label="Categoria Pai (opcional)" name="parent_id">
            <Select
              value={parentId}
              onChange={(value) => setParentId(value)}
              placeholder="Selecione uma categoria pai"
              allowClear
            >
              {parentCategories.map((category) => (
                <Option key={category._id} value={category._id}>
                  {category.displayName}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item style={{ textAlign: 'right' }}>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              disabled={loading}
              style={{ width: '140px' }}
            >
              {categorySlug ? 'Atualizar' : 'Adicionar'}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default CategoryForm;
