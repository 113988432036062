import jwt from "jsonwebtoken";
import socketIOClient from "socket.io-client";
import { JWT_SIGNIN_KEY, accessTokenKey, SERVER_URL, persistAdminProfile } from "./config";
import Cookies from "js-cookie";

var socketUser
export const socket = () => {
  socketUser = socketIOClient(SERVER_URL, { transport : ['websocket'] })
  return socketUser
};

export const disconnectSocket = () => {
  return socketUser.close()
}

// export const getRealTimeNotifications = () => {
//   return socketUser.on("notification", data => data);
// }

export const isAdminOnLocalStorage = () => {
  const admin = JSON.parse(localStorage.getItem(persistAdminProfile))
  if (admin && admin.role === 'admin') {
    return true;
  }
  return false
}

export const decodeLocalStorage = () => {
    const token = localStorage.getItem(accessTokenKey);

    if(token){
        const decoded = jwt.decode(token);
        return decoded;
    }
    return null;

}

export const verifyLocalStorage = () => {
    if (typeof window == "undefined") {
      return false;
    }
  
    let jsontoken = localStorage.getItem(accessTokenKey);
  
    let data;
  
    if (jsontoken) {
      let token = jsontoken;
      jwt.verify(token, JWT_SIGNIN_KEY, async (err, decoded) => {
        if (err) {
          //need to check for other error as well..
          if (err.expiredAt !== undefined) {
            data = {user: { ...jwt.decode(token, JWT_SIGNIN_KEY)}};
          } else {
            data = false;
          }
        } else {
          data = decoded
        }
      });
      // localStorage.setItem(accessTokenKey, token);	
      return data;
    } else {
      return false;
    }
  };

export const districts = [
  "Acre",
  "Alagoas",
  "Amapá",
  "Amazonas",
  "Bahia",
  "Ceará",
  "Distrito Federal",
  "Espírito Santo",
  "Goiás",
  "Maranhão",
  "Mato Grosso",
  "Mato Grosso do Sul",
  "Minas Gerais",
  "Pará",
  "Paraíba",
  "Paraná",
  "Pernambuco",
  "Piauí",
  "Rio de Janeiro",
  "Rio Grande do Norte",
  "Rio Grande do Sul",
  "Rondônia",
  "Roraima",
  "Santa Catarina",
  "São Paulo",
  "Sergipe",
  "Tocantins",
  "Todos"
]

export const productColors = [
  "Azul",
  "Vermelho",
  "Verde",
  "Amarelo",
  "Preto",
  "Branco",
  "Cinza",
  "Roxo",
  "Laranja",
  "Rosa",
  "Prata",
];

export const productWarranty = [
  "7 Dias",
  "14 Dias",
  "1 Mês",
  "2 Meses",
  "3 Meses",
  "6 Meses",
  "1 Ano",
]

// export const setCookie = (key, value) => {
//   if (typeof window !== "undefined") {
//     Cookies.set(key, value, {
//       expires: 1, // 1 dia
//       path: "/",
//     });
//   }
// };

export const setCookie = (key, value) => {
  if (typeof window !== "undefined") {
    // Verificar se estamos em um ambiente local (localhost)
    const isLocal = window.location.hostname === 'localhost';

    Cookies.set(key, value, {
      expires: 1, // 1 dia
      path: "/",
      domain: isLocal ? 'localhost' : '.webpecas.shop', // Se estiver em localhost, o domínio será localhost, senão será o domínio principal
      secure: !isLocal, // Cookies seguros apenas em produção (HTTPS)
      sameSite: 'Lax', // Ou 'Strict' dependendo do caso
    });
  }
};