import React, { useState, useEffect } from 'react';
import { Form, Input, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { putBrand, getBrands } from '../../../../redux/actions/superadmin_action';
import { useParams, useHistory } from 'react-router-dom';

const BrandForm = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const brands = useSelector(state => state.superadmin?.brands || []);
  const { slug } = useParams(); // Acessando o slug da URL
  let brand = null;
  const history = useHistory();

  // Carregar as marcas
  useEffect(() => {
    dispatch(getBrands());
  }, [dispatch]);

  // Encontrar a marca correspondente ao slug
  useEffect(() => {
    brand = brands.find(brand => brand.slug === slug);
    if (brand) {
      form.setFieldsValue({ brandName: brand.brandName }); // Setar o valor inicial do formulário
    }
  }, [brands, slug, form]);

  const handleSubmit = async (values) => {
    if (!brand) return; 

    setLoading(true);
    await dispatch(putBrand({ brandName: values.brandName, brand_id: brand._id }));
    setLoading(false);
    form.resetFields(); 
  };

  const handleGoBack = () => {
    history.goBack(); 
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div style={{ width: '100%', maxWidth: '400px', padding: '20px' }}>
        <h2 style={{ textAlign: 'center' }}>Alterar Marca</h2>
        <Form
          form={form}
          name="brandForm"
          onFinish={handleSubmit}
          initialValues={{ brandName: '' }}
          layout="vertical"
        >
          <Form.Item
            label="Nome da Marca"
            name="brandName"
            rules={[{ required: true, message: 'Por favor, insira o nome da marca!' }]}
          >
            <Input 
              placeholder="Nome da marca"  
            />
          </Form.Item>

          <Form.Item style={{ textAlign: 'right' }}>
            <Button onClick={handleGoBack} type="default" style={{ marginRight: '10px' }}>
              Voltar
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              disabled={loading}
              style={{ width: '140px' }}
            >
              Alterar Marca
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default BrandForm;