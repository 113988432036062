import React from 'react'
import Layout from '../../../core/Layout'
import CategoryForm from './CategoryForm'

function index() {
  return (
    <Layout>
      <CategoryForm/>
    </Layout>
  )
}

export default index
