import React, { useState } from "react";
import { Button } from "antd";
import ReCAPTCHA from "react-google-recaptcha"

function ResetPasswordForm({ handleChange, handleSubmit, state, loading }) {
  const [value, setValue] = useState({ hidden: true });
  const [capVal, setCapVal] = useState(null);
  
  const toggleShow = () => {
    setValue({ hidden: !value.hidden });
  };

  return (
    <form className="login-form" onSubmit={handleSubmit}  autoComplete="on">
      <h1 className="text-center">Esqueci Minha Senha</h1>
      <h2 className="text-center">Digite seu e-mail para redefinir sua senha</h2>
      <div className="form-group">
        <input
          type="email"
          className="form-control"
          placeholder="email"
          name="email"
          autoComplete="on"
          onChange={handleChange}
          value={state.email}
          required
        />
      </div>

      <div className="resert-password-container"><a href="/" className="resert-password-link">Login</a></div>
      <div className="form-group">
        <div className="re-captcha">
          <ReCAPTCHA 
              sitekey="6LfcqZ0qAAAAAFauEZBgQ6v3axzrxRNBGCzV7SWK"
              onChange={(val) => setCapVal(val)} 
          />
        </div>
        <Button
          className="btn btn-primary btn-block"
          htmlType="submit"
          loading={loading}
          disabled={!capVal}
        >
          Login
        </Button>
      </div>
    </form>
  );
}

export default ResetPasswordForm;
