import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, message, Form, Input, Button } from 'antd';
import { SideBySideMagnifier } from "react-image-magnifiers";
import { createBanner } from '../../../../redux/actions/superadmin_action';


const BannerForm = ({ superadmin, createBanner, uploadedImages, form }) => {
    const [file, setFile] = useState(null);
    const [preview, setPreview] = useState({
        previewVisible: false,
        previewImage: '',
        previewTitle: ''
    });
    const [link, setLink] = useState('');
    const [productSlug, setProductSlug] = useState('');
    const [description, setDescription] = useState('');

    useEffect(() => {
        if (form) {
            form.setFieldsValue({
                images: uploadedImages.map(image => image.response?._id)
            });
        }
    }, [uploadedImages, form]);

    const getBase64 = file => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    };

    const handleCancel = () => setPreview({ ...preview, previewVisible: false });

    const onPreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file);
        }
        setPreview({
            previewImage: file.url || file.preview,
            previewVisible: true,
            previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
        });
    };

    const handleFileChange = async e => {
        const selectedFile = e.target.files[0];
        if (!selectedFile) {
            return;
        }

        const isJpgOrPng = selectedFile.type === 'image/jpeg' || selectedFile.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('Você só pode enviar arquivos JPG/PNG!');
            return;
        }
        const isOverSize = selectedFile.size > 2480 * 3230;
        if (isOverSize) {
            message.error('A imagem deve ser menor que 2480 * 32308!');
            return;
        }

        setFile(selectedFile);

        const previewImage = await getBase64(selectedFile);
        setPreview({
            previewImage,
            previewVisible: false,
            previewTitle: selectedFile.name,
        });
    };

    const handleSubmit = async () => {
        if (!description) {
            message.error("A descrição do banner é obrigatória");
            return;
        }

        if (!file) {
            message.error("A imagem do banner é obrigatória");
            return;
        }

        const body = {
            link,
            productSlug,
            description
        };

        const formData = new FormData();
        formData.append('bannerPhoto', file);

        for(const key in body){
            formData.append(key, body[key]);
        }

        try {
            await createBanner(formData);
        } catch (error) {
            message.error("Erro ao criar o banner");
        }      
        
    };

    return (
        <>
            <Form layout="vertical" onFinish={handleSubmit}>
                <Form.Item label="Descrição">
                    <Input value={description} onChange={e => setDescription(e.target.value)} />
                </Form.Item>
                <Form.Item label="Link">
                    <Input value={link} onChange={e => setLink(e.target.value)} />
                </Form.Item>
                <Form.Item label="Slug do produto">
                    <Input value={productSlug} onChange={e => setProductSlug(e.target.value)} />
                </Form.Item>
                <Form.Item label="Imagem">
                    <input
                        type="file"
                        className="form-control-file"
                        id="bannerPhoto"
                        name="bannerPhoto"
                        onChange={handleFileChange}
                    />
                    {file && (
                        <Button
                            type="link"
                            onClick={() => setPreview({ ...preview, previewVisible: true })}
                        >
                            Visualizar Imagem
                        </Button>
                    )}
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Adicionar Banner
                    </Button>
                </Form.Item>
            </Form>
            <Modal
                open={preview.previewVisible}
                title={preview.previewTitle}
                footer={null}
                onCancel={handleCancel}
            >
                <SideBySideMagnifier
                    style={{ height: '100%', width: '100%' }}
                    alwaysInPlace={true}
                    imageSrc={preview.previewImage}
                    imageAlt="Example"
                />
            </Modal>
        </>
    );
};

BannerForm.propTypes = {
    superadmin: PropTypes.object,
    createBanner: PropTypes.func.isRequired,
    uploadedImages: PropTypes.array,
    form: PropTypes.object,
};

const mapStateToProps = state => ({
    superadmin: state.auth.authUser,
    uploadedImages: state.product.uploadedImages,
});

const mapDispatchToProps = {
    createBanner,
};

export default connect(mapStateToProps, mapDispatchToProps)(BannerForm);


// Codigo estavel
// import React, { useState } from 'react';
// import PropTypes from 'prop-types';
// import { connect } from 'react-redux';
// import { Modal, message, Form, Input, Button } from 'antd';
// import { SideBySideMagnifier } from "react-image-magnifiers";
// import { createBanner } from '../../../../redux/actions/superadmin_action';

// const BannerForm = ({ superadmin, createBanner, uploadedImages, form }) => {
//     const [file, setFile] = useState(null);
//     const [preview, setPreview] = useState({
//         previewVisible: false,
//         previewImage: '',
//         previewTitle: ''
//     });
//     const [link, setLink] = useState('');
//     const [productSlug, setProductSlug] = useState('');

//     const getBase64 = file => {
//         return new Promise((resolve, reject) => {
//             const reader = new FileReader();
//             reader.readAsDataURL(file);
//             reader.onload = () => resolve(reader.result);
//             reader.onerror = error => reject(error);
//         });
//     };

//     const handleCancel = () => setPreview({ ...preview, previewVisible: false });
//     //const handleShow = () => setPreview({ ...preview, previewVisible: true });

//     const onPreview = async file => {
//         if (!file.url && !file.preview) {
//             file.preview = await getBase64(file);
//         }
//         setPreview({
//             previewImage: file.url || file.preview,
//             previewVisible: true,
//             previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
//         });
//     };

//     const handleFileChange = async e => {
//         const selectedFile = e.target.files[0];
//         if (!selectedFile) {
//             return;
//         }

//         const isJpgOrPng = selectedFile.type === 'image/jpeg' || selectedFile.type === 'image/png';
//         if (!isJpgOrPng) {
//             message.error('Você só pode enviar arquivos JPG/PNG!');
//             return;
//         }
//         const isOverSize = selectedFile.size > 2480 * 3230;
//         if (isOverSize) {
//             message.error('A imagem deve ser menor que 2480 * 32308!');
//             return;
//         }

//         setFile(selectedFile);

//         const previewImage = await getBase64(selectedFile);
//         setPreview({
//             previewImage,
//             previewVisible: true,
//             previewTitle: selectedFile.name,
//         });
//     };

//     const handleSubmit = async () => {
//         if (!file) {
//             message.error("A imagem do banner é obrigatória");
//             return;
//         }

//         const body = {
//             link,
//             productSlug
//         };

//         try {
//             const response = await createBanner(body, file);
//             if (response.isSuccess) {
//                 message.success("Banner criado com sucesso!");
//             } else {
//                 message.error(response.errorMessage);
//             }
//         } catch (error) {
//             message.error("Erro ao criar o banner");
//         }
//     };

//     return (
//         <>
//             <Form layout="vertical" onFinish={handleSubmit}>
//                 <Form.Item label="Slug do produto">
//                     <Input value={productSlug} onChange={e => setProductSlug(e.target.value)} />
//                 </Form.Item>
//                 <Form.Item label="Link">
//                     <Input value={link} onChange={e => setLink(e.target.value)} />
//                 </Form.Item>
//                 <Form.Item label="Imagem">
//                     <input
//                         type="file"
//                         className="form-control-file"
//                         id="bannerPhoto"
//                         name="bannerPhoto"
//                         onChange={handleFileChange}
//                     />
//                 </Form.Item>
//                 <Form.Item>
//                     <Button type="primary" htmlType="submit">
//                         Enviar
//                     </Button>
//                 </Form.Item>
//             </Form>
//             <Modal
//                 open={preview.previewVisible}
//                 title={preview.previewTitle}
//                 footer={null}
//                 onCancel={handleCancel}
//             >
//                 <SideBySideMagnifier
//                     style={{ height: '100%', width: '100%' }}
//                     alwaysInPlace={true}
//                     imageSrc={preview.previewImage}
//                     imageAlt="Example"
//                 />
//             </Modal>
//         </>
//     );
// };

// BannerForm.propTypes = {
//     superadmin: PropTypes.object,
//     uploadedImages: PropTypes.array,
//     createBanner: PropTypes.func.isRequired,
//     form: PropTypes.object,
// };

// const mapStateToProps = state => ({
//     superadmin: state.auth.authUser,
//     uploadedImages: state.product.uploadedImages,
// });

// const mapDispatchToProps = {
//     createBanner,
// };

// export default connect(mapStateToProps, mapDispatchToProps)(BannerForm);
