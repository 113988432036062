import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import "antd/dist/antd.css";
import setAuthToken from "./utils/setAuthToken";
import MainRouter from "./router/MainRouter";
import "./App.scss";
import Signin from "./components/pages/Signin";
import ResetPassaword from "./components/pages/ResetPassword";
import { loadMe } from "./redux/actions/auth_actions";
import store from "./redux/store";
import { verifyLocalStorage} from "./utils/common";
import Alert from "./components/common/Alert";
import { useLocation } from "react-router-dom"; 

if (localStorage.token) {
  setAuthToken(localStorage.token);
}
const App = (props) => {
  const location = useLocation(); // Obtém a rota atual
  useSelector(state => state.auth.isAuth)

  useEffect( () => {
    if(!localStorage.token) return;
    store.dispatch(loadMe());//returns socket user obj else null      
  }, []);
  
  // Renderiza o componente correto com base na rota e no estado de autenticação
  if (location.pathname === "/reset-password" && !verifyLocalStorage()) {
    return (
    <>
      <Alert />
      <ResetPassaword />;
    </>)
  }

  return (
    <>
      <Alert />
      {!verifyLocalStorage() ? <Signin />  : <MainRouter />}
    </>
  );
};


export default App;
