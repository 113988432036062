import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';
import moment from 'moment';
import { getNotifications, readNotification } from '../../../redux/actions/notification_actions';

const NotificationBar = ({ socket, getNotifications, adminNotification, readNotification }) => {
  const [numberOfNotifications, setnumberOfNotifications] = useState(0);

  useEffect(() => {
    setnumberOfNotifications(adminNotification.noOfUnseen);
  }, [adminNotification]);

  useEffect(() => {
    if (!isEmpty(socket)) {
      socket.on("notification", data => {
        setnumberOfNotifications(data.noOfUnseen);
      });
    }
    return () => {
      // socket.off("notification")
    };
  }, [socket]);

  const saveNotificationSlug = (slug) => {
    // Verifica se o slug já foi armazenado
    sessionStorage.setItem('openedSlug', slug);
    sessionStorage.setItem('notificationOpenedModal', 'false');
  };

  const handleRowClick = (record) => {
    switch (record.notificationType) {
      case 'payment_order':
      case 'created_order':
        // Redirecionar para a página de pedidos
        if (record.notificationDetail.onOrderId) {
          saveNotificationSlug(record.notificationDetail.onOrderId)
        } 

        window.location.href = "/order";

        if(!record.hasRead){
          readNotification(record._id)
        }
      break;
  
      case 'question_on_product':
        // Redirecionar para a página do produto com o ID do produto
        if (record.notificationDetail.onProductSlug) {
          saveNotificationSlug(record.notificationDetail.onProductSlug)
        } 

        window.location.href = "/manage-products";
        if(!record.hasRead){
          readNotification(record._id)
        }
      break;
  
      case 'answer_on_product':
        // Redirecionar para a página relacionada à resposta no produto
        window.location.href = "/product";
        break;
  
      case 'review':
        // Redirecionar para uma página de avaliações ou produto
        window.location.href = "/reviews";
        break;
  
      default:
        // Redirecionar para uma rota padrão, se necessário
        window.location.href = "/";
        break;
    }
  };

  // Função para renderizar uma notificação
  const renderNotification = (notificationObj) => {
    const { notificationType, notificationDetail, date, _id } = notificationObj;
    switch (notificationType) {
      case 'question_on_product':
        return (
          <div key={_id} onClick={() => handleRowClick(notificationObj)} className="list-group-item"  style={{cursor:'pointer'}}>
            <div className={`row no-gutters align-items-center`}>
              <div className="col-2">
                <i className="text-primary mr-2 fas fa-question-circle"></i>
              </div>
              <div className="col-10">
                <div className="text-dark">Pergunta feita</div>
                <div className="text-muted small mt-1">
                  <strong>{notificationDetail.questionBy}</strong> fez uma pergunta sobre o produto <strong>{notificationDetail.onProduct}</strong>
                </div>
                <div className="text-muted small mt-1">{moment(date).fromNow()}</div>
              </div>
            </div>
          </div>
        );
      case 'payment_order':
        return (
          <div key={_id} onClick={() => handleRowClick(notificationObj)} className="list-group-item"  style={{cursor:'pointer'}}>
            <div className={`row no-gutters align-items-center`}>
              <div className="col-2">
                <i className="align-middle mr-2 fas fa-fw fa-dollar-sign text-success"></i>
              </div>
              <div className="col-10">
                <div className="text-dark">Pagamento Aprovado</div>
                <div className="text-muted small mt-1">
                  <strong>{notificationDetail.name}</strong> Confirmou o pagamento do pedido <strong>{notificationDetail.onProduct}</strong>
                </div>
                <div className="text-muted small mt-1">{moment(date).fromNow()}</div>
              </div>
            </div>
          </div>
        );
        case 'created_order':
          return (
            <div key={_id} to='/notification' onClick={() => handleRowClick(notificationObj)} className="list-group-item" style={{cursor:'pointer'}}>
              <div className={`row no-gutters align-items-center`}>
                <div className="col-2">
                  <i className="text-success mr-2 fas fa-shopping-cart"></i>
                </div>
                <div className="col-10">
                  <div className="text-dark">Pedido Realizado</div>
                  <div className="text-muted small mt-1">
                  <strong>{notificationDetail.name}</strong> fez o pedido do produto {notificationDetail.onProduct}
                  </div>
                  <div className="text-muted small mt-1">{moment(date).fromNow()}</div>
                </div>
              </div>
            </div>
          );
      default:
        return null;
    }
  };

  return (
    <li className="nav-item dropdown" onClick={getNotifications}>
      <Link className="nav-icon dropdown-toggle" to='/notification' id="alertsDropdown" data-toggle="dropdown">
        <div className="position-relative">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-bell align-middle">
            <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
            <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
          </svg>
          {numberOfNotifications > 0 ? <span className="indicator">{numberOfNotifications}</span> : null}
        </div>
      </Link>
      <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right py-0" aria-labelledby="alertsDropdown">
        <div className="list-group">
          {/* Exibe as 4 notificações mais recentes */}
          {adminNotification.notifications.slice(0, 4).map(n => renderNotification(n))}

          {/* Se houver mais notificações, exibe o link para mostrar mais */}
          {adminNotification.notifications.length > 4 && (
            <Link to='/notification' className="list-group-item">
              <div className="row no-gutters align-items-center">
                <div className="col-12">
                  <div className="text-dark text-center">Mostrar mais</div>
                </div>
              </div>
            </Link>
          )}

          {/* Se já estiver mostrando todas as notificações, não exibe "Mostrar mais" */}
          {adminNotification.notifications.length === 4 && adminNotification.notifications.map(n => renderNotification(n))}

					{!adminNotification.notifications.length && (
						<Link to='/notification' className="list-group-item">
	 						<div className="row no-gutters align-items-center">
	 							<div className="col-2">
	 								<i className=" text-warning align-middle mr-2 fas fa-fw fa-bell"></i>
	 							</div>
	 							<div className="col-10">
	 								<div className="text-dark">Sem notificação</div>
	 								<div className="text-muted small mt-1">Você ainda não possui notificação!</div>
	 							</div>
	 						</div>
	 					</Link>
					)}
        </div>
      </div>
    </li>
  );
};

NotificationBar.propTypes = {
  socket: PropTypes.object,
  getNotifications: PropTypes.func.isRequired,
  adminNotification: PropTypes.object.isRequired,
  readNotification: PropTypes.func.isRequired,
  userProfile: PropTypes.object.isRequired, // Adicionado para verificar o tipo de usuário
};

const mapStateToProps = state => ({
  socket: state.socket,
  adminNotification: state.notification,
  userProfile: state.auth, // O papel do usuário deve vir do estado global (assumindo que seja 'auth')
});

export default connect(mapStateToProps, { getNotifications, readNotification })(NotificationBar);

// import React, { useState, useEffect } from 'react'
// import { isEmpty } from 'lodash'
// import { connect } from "react-redux";
// import PropTypes from "prop-types";
// import { Link } from 'react-router-dom';
// import moment from 'moment'
// import { getNotifications, readNotification } from '../../../redux/actions/notification_actions'

// const NotificationBar = ({ socket, getNotifications, adminNotification, readNotification }) => {
// 	const [numberOfNotifications, setnumberOfNotifications] = useState(0)
// 	useEffect(()=> {
// 		// console.log(adminNotification);
// 		setnumberOfNotifications(adminNotification.noOfUnseen)
// 	}, [adminNotification])
// 	useEffect(() => {
// 		if (!isEmpty(socket)) {
// 			socket.on("notification", data => {
// 					setnumberOfNotifications(data.noOfUnseen)
// 			});
// 		}
// 		return () => {
// 			// console.log(socket);
// 			// socket.off("notification")
// 		}
// 	}, [socket])

// 	const renderNotification = (notificationObj) => {
// 		const { notificationType, notificationDetail, hasRead, date, _id} = notificationObj
// 		switch (notificationType) {
// 			case 'question_on_product':
// 				return <Link key={_id} to='/' onClick={() => readNotification(_id)} className="list-group-item">
// 					<div className={`row no-gutters align-items-center `}>
// 						<div className="col-2">
// 							<i className="align-middle mr-2 fas fa-fw fa-question-circle"></i>
// 						</div>
// 						<div className="col-10">
// 							<div className="text-dark">Pergunta feita</div>
// 							<div className="text-muted small mt-1">
// 								<strong>{notificationDetail.questionBy} </strong>fez uma pergunta sobre o produto <strong>{notificationDetail.onProduct}</strong></div>
// 							<div className="text-muted small mt-1">{moment(date).fromNow()}</div>
// 						</div>
// 					</div>
// 				</Link>
// 			default:
// 				return null;
// 		}
// 	}

// 	return (
// 		<li className="nav-item dropdown" onClick={getNotifications}>
// 			<Link className="nav-icon dropdown-toggle" to=''  id="alertsDropdown" data-toggle="dropdown">
// 				<div className="position-relative">
// 					{/* <i  className="align-middle" data-feather="bell"></i> */}
// 					<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-bell align-middle"><path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path><path d="M13.73 21a2 2 0 0 1-3.46 0"></path></svg>
// 					{numberOfNotifications>0?<span className="indicator">{numberOfNotifications}</span>:null}
// 				</div>
// 			</Link>
// 			<div className="dropdown-menu dropdown-menu-lg dropdown-menu-right py-0" aria-labelledby="alertsDropdown">
// 				{/* <div className="dropdown-menu-header">
// 								4 New Notifications
// 								</div> */}
// 				<div className="list-group">
// 				{
// 					adminNotification.notifications.map(n=>renderNotification(n))
// 				}
// 					<Link to='' className="list-group-item">
// 						<div className="row no-gutters align-items-center">
// 							<div className="col-2">
// 								<i className="align-middle mr-2 fas fa-fw fa-question-circle"></i>
// 							</div>
// 							<div className="col-10">
// 								<div className="text-dark">Update completed</div>
// 								<div className="text-muted small mt-1">Restart server 12 to complete the update.</div>
// 								<div className="text-muted small mt-1">2h ago</div>
// 							</div>
// 						</div>
// 					</Link>
// 					<Link to='' className="list-group-item">
// 						<div className="row no-gutters align-items-center">
// 							<div className="col-2">
// 								<i className="text-warning" data-feather="bell"></i>
// 							</div>
// 							<div className="col-10">
// 								<div className="text-dark">Lorem ipsum</div>
// 								<div className="text-muted small mt-1">Aliquam ex eros, imperdiet vulputate hendrerit et.</div>
// 								<div className="text-muted small mt-1">6h ago</div>
// 							</div>
// 						</div>
// 					</Link>
// 					<Link to={'/'} className="list-group-item">
// 						<div className="row no-gutters align-items-center">
// 							<div className="col-2">
// 								<i className="text-primary" data-feather="message-circle"></i>
// 							</div>
// 							<div className="col-10">
// 								<div className="text-dark">Login from 192.186.1.1</div>
// 								<div className="text-muted small mt-1">8h ago</div>
// 							</div>
// 						</div>
// 					</Link>
// 					<Link to='' className="list-group-item">
// 						<div className="row no-gutters align-items-center">
// 							<div className="col-2">
// 								<i className="text-success" data-feather="user-plus"></i>
// 							</div>
// 							<div className="col-10">
// 								<div className="text-dark">New connection</div>
// 								<div className="text-muted small mt-1">Anna accepted your request.</div>
// 								<div className="text-muted small mt-1">12h ago</div>
// 							</div>
// 						</div>
// 					</Link>
// 				</div>
// 				<div className="dropdown-menu-footer">
// 					<Link to='' className="text-muted">Show all notifications</Link>
// 				</div>
// 			</div>
// 		</li>

// 	)
// }
// NotificationBar.propTypes = {
// 	socket: PropTypes.object,
// 	getNotifications: PropTypes.func.isRequired,
// 	adminNotification: PropTypes.object,
// 	readNotification: PropTypes.func.isRequired
// }
// const mapStateToProps = state => ({
// 	socket: state.socket,
// 	adminNotification: state.notification
// })
// export default connect(mapStateToProps, {getNotifications, readNotification})(NotificationBar)
