import React, { useState, useEffect } from 'react';
import { Upload, Button, Modal, message } from 'antd';
import { UploadOutlined, FilePdfOutlined } from '@ant-design/icons';
import { uploadNotaFiscal, getOrder } from '../../../redux/actions/order_actions';
import { useDispatch } from 'react-redux';

const NotaFiscalComponent = (props) => {
  const [fileList, setFileList] = useState([]); // Lista de arquivos
  const [isModalVisible, setIsModalVisible] = useState(false); // Controle do modal
  const [uploadedFile, setUploadedFile] = useState(null); // Arquivo enviado
  const dispatch = useDispatch();

  // Limpa a URL temporária ao desmontar o componente
  useEffect(() => {
    return () => {
      if (uploadedFile instanceof File || uploadedFile instanceof Blob) {
        URL.revokeObjectURL(getPdfUrl());
      }
    };
  }, [uploadedFile]);

  // Configurações do Upload
  const uploadProps = {
    onRemove: (file) => {
      setFileList((prevList) => prevList.filter((item) => item.uid !== file.uid));
    },
    beforeUpload: (file) => {
      const isPDF = file.type === 'application/pdf';
      if (!isPDF) {
        message.error('Apenas arquivos PDF são permitidos!');
      } else {
        setFileList([file]);
      }
      return false; // Impede o upload automático
    },
    fileList,
    showUploadList: false, // Oculta a lista de arquivos
  };

  // Função para exibir o modal
  const handleViewNotaFiscal = () => {
    if (fileList.length > 0) {
      setUploadedFile(fileList[0]);
      setIsModalVisible(true);
    } else {
      message.error('Nenhum arquivo foi enviado.');
    }
  };

  // Função para exibir o modal do bucket
  const handleViewNotaFiscalBucket = () => {
    setUploadedFile(`${process.env.REACT_APP_IMAGE_BASE_URL}/${props?.order?.invoice.urlInvoice}`);
    setIsModalVisible(true);
  };

  // Função para fechar o modal
  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  // Função para enviar o arquivo para o backend
  const handleSendNotaFiscal = async () => {
    if (fileList.length === 0) {
      message.error('Nenhum arquivo foi enviado.');
      return;
    }

    const formData = new FormData();
    formData.append('notaFiscal', fileList[0]);

    await dispatch(uploadNotaFiscal(formData, props.order?.soldBy, props?.order?._id));
    await dispatch(getOrder(props.order?.soldBy, props?.order?._id));

    // Reseta a fileList após o envio bem-sucedido
    setFileList([]);
  };

  // Função para obter a URL do PDF
  const getPdfUrl = () => {
    if (!uploadedFile) return null;

    // Se o arquivo for um objeto File (upload local), cria uma URL temporária
    if (uploadedFile instanceof File || uploadedFile instanceof Blob) {
      return URL.createObjectURL(uploadedFile);
    }

    // Se for uma URL (vinda do bucket), usa diretamente
    return uploadedFile;
  };

  // Limpa a URL temporária ao desmontar o componente
  useEffect(() => {
    return () => {
      if (uploadedFile instanceof File || uploadedFile instanceof Blob) {
        URL.revokeObjectURL(getPdfUrl());
      }
    };
  }, [uploadedFile]);

  return (
    <div style={{ padding: '20px' }}>
      {/* Upload de Nota Fiscal */}
      {!props?.order?.invoice.postingDate && (
        <Upload {...uploadProps}>
          <Button icon={<UploadOutlined />}>Anexar Nota Fiscal</Button>
        </Upload>
      )}

      {/* Botão para visualizar a nota fiscal (upload local) */}
      {fileList.length > 0 && (
        <>
          <Button
            type="link"
            icon={<FilePdfOutlined />}
            onClick={handleViewNotaFiscal}
            style={{ marginRight: '10px' }}
          >
            Ver Nota Fiscal
          </Button>
          <Button
            type="primary"
            onClick={handleSendNotaFiscal}
            style={{ marginTop: '20px' }}
          >
            Enviar Nota Fiscal para o Cliente
          </Button>
        </>
      )}

      {/* Botão para visualizar a nota fiscal (bucket) */}
      {props?.order?.invoice.postingDate && (
        <Button
          type="link"
          icon={<FilePdfOutlined />}
          onClick={handleViewNotaFiscalBucket}
          style={{ marginLeft: '10px' }}
        >
          Ver Nota Fiscal
        </Button>
      )}

      {/* Modal para exibir o PDF */}
      <Modal
        title="Visualizar Nota Fiscal"
        open={isModalVisible}
        onCancel={handleCloseModal}
        footer={null}
        width="80%"
        style={{ maxWidth: '1200px' }}
      >
        {uploadedFile && (
          <iframe
            title="Nota Fiscal"
            src={getPdfUrl()}
            width="100%"
            height="550px"
            style={{ border: 'none' }}
          />
        )}
      </Modal>
    </div>
  );
};

export default NotaFiscalComponent;