import React, { useEffect, useState, useRef } from 'react';
import { Table, Input, Button, Space, Spin } from 'antd';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getCategories } from '../../../redux/actions/superadmin_action';
import { useHistory } from 'react-router-dom';

const CategoryList = ({ categories = [], loading, getCategories }) => {
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const history = useHistory();

    useEffect(() => {
        getCategories();
    }, [getCategories]);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = clearFilters => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Buscar ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Buscar
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Resetar
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    });

    const columns = [
        {
            title: 'Categoria',
            dataIndex: 'displayName',
            key: 'displayName',
            ...getColumnSearchProps('displayName'),
        },
        {
            title: 'Slug',
            dataIndex: 'slug',
            key: 'slug',
            ...getColumnSearchProps('slug'),
        },
        {
          title: 'ID',
          dataIndex: '_id',
          key: '_id',
        },
        {
          title: 'System Name',
          dataIndex: 'systemName',
          key: 'systemName',
        },
        {
          title: 'Parent',
          dataIndex: 'parent',
          key: 'parent',
          ...getColumnSearchProps('parent'),
        },
        {
          title: 'Ativo',
          dataIndex: 'isDisabled',
          key: 'isDisabled',
        },
        {
            title: 'Ações',
            key: 'actions',
            render: (_, record) => (
              <button
                  onClick={() => history.push(`/superadmin/edit-category/${record.slug}`)}
                  className="btn btn-warning btn-sm"
              >
                  <i className="fas fa-pen"/> Editar
              </button>
            ),
        },
    ];

    const data = categories.map(category => ({
        key: category._id,
        displayName: category.displayName,
        slug: category.slug,
        _id: category._id,
        systemName: category.systemName,
        isDisabled: category.isDisabled == null ? 'Ativo':'Inativo',
        //parent: categories[category.parent] || 'Nenhum',
        parent: categories.find(cat => cat._id === category.parent)?.displayName || 'Nenhum',
    }));

    const addCategory = () =>{
      window.location.href = "/superadmin/add-category";
    }

    console.log(categories);
    return (
        <Spin spinning={loading}>
            <div style={{ display: "flex", justifyContent: "flex-end", margin: "20px" }}>
              <Button type="primary" onClick={addCategory} style={{display:'flex', alignItems: 'center'}}>
                <PlusOutlined /> Adicionar Categoria
              </Button>
            </div>
            <Table columns={columns} dataSource={data} />
        </Spin>
    );
};

CategoryList.propTypes = {
    categories: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    loading: PropTypes.bool.isRequired,
    getCategories: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
    //console.log("Redux State:", state); // Para depuração state
    return {
        categories: state.superadmin?.categories || [],
        loading: state.superadmin?.loading || false,
    };
}

export default connect(mapStateToProps, { getCategories })(CategoryList);