import React, { useState, useEffect } from 'react';
import { Table, Button, message, Select, DatePicker, Popconfirm } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import { getNotifications, readNotification } from '../../../redux/actions/notification_actions';
import { isEmpty } from 'lodash';
import Layout from '../../core/Layout';
import { connect } from 'react-redux';

const { Option } = Select;
const { RangePicker } = DatePicker;

const NotificationTable = ({ socket, getNotifications, adminNotification, readNotification }) => {
  const [notifications, setNotifications] = useState([]);
  const [filteredNotifications, setFilteredNotifications] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(5);
  const [filter, setFilter] = useState('all'); // Filtro para 'todas', 'lidas' ou 'não lidas'
  const [dateRange, setDateRange] = useState([]); // Filtro de data

  useEffect(() => {
    if (adminNotification && !isEmpty(adminNotification.notifications)) {
      // Ordena as notificações pela data (mais recentes primeiro)
      const sortedNotifications = [...adminNotification.notifications];

      setNotifications(sortedNotifications);
      setFilteredNotifications(sortedNotifications); // Exibe todas as notificações inicialmente
    }
  }, [adminNotification]);

  useEffect(() => {
    getNotifications();
  }, [getNotifications]);

  
  useEffect(() => {
    if (!isEmpty(socket)) {
      socket.on('notification', data => {
        // Atualiza as notificações quando um novo evento é recebido
        getNotifications();
      });
    }
    return () => {
      if (socket.off) {
        //socket.off('notification');
      }
    };
  }, [socket, getNotifications]);

  useEffect(() => {
    // Filtra as notificações conforme o filtro selecionado (lidas, não lidas ou todas)
    let filtered = notifications;
    if (filter === 'read') {
      filtered = filtered.filter(n => n.hasRead === true);
    } else if (filter === 'unread') {
      filtered = filtered.filter(n => n.hasRead === false);
    }

    // Filtro de data
    if (dateRange.length > 0) {
      const [startDate, endDate] = dateRange;
      filtered = filtered.filter(n => {
        const notificationDate = moment(n.date);
        return notificationDate.isBetween(startDate, endDate, null, '[]');
      });
    }

    setFilteredNotifications(filtered);
  }, [filter, notifications, dateRange]);

  const renderNotificationContent = (notification) => {
    const { notificationType, notificationDetail, date, _id } = notification;
    let content = '';
    switch (notificationType) {
      case 'question_on_product':
        content = (
          <div>
            Pergunta feita por <strong>{notificationDetail.questionBy}</strong> sobre o produto <strong>{notificationDetail.onProduct}</strong>.
          </div>
        );
        break;
      case 'payment_order':
        content = (
          <div>
            O <strong>{notificationDetail.name}</strong> Confirmou o pagamento do pedido <strong>{notificationDetail.onProduct},</strong> agora você precisa <strong>Aprovar</strong> para receber o Código de postagem
          </div>
        );
        break;
      case 'created_order':
        content = (
          <div>
            <strong>{notificationDetail.name}</strong> fez o pedido do produto {notificationDetail.onProduct}
          </div>
        );
        break;
      case 'answer_on_product':
        content = (
          <div>
            Resposta feita por <strong>{notificationDetail.answerBy}</strong> sobre a pergunta no produto <strong>{notificationDetail.onProduct}</strong>.
          </div>
        );
        break;
      case 'review':
        content = (
          <div>
            Avaliação deixada por <strong>{notificationDetail.reviewer}</strong> para o produto <strong>{notificationDetail.onProduct}</strong>.
          </div>
        );
        break;
      default:
        content = <div>Notificação desconhecida.</div>;
        break;
    }
    return (
      <div>
        {content}
        <div className="text-muted small mt-1">{moment(date).fromNow()}</div>
      </div>
    );
  };

  const saveNotificationSlug = (slug) => {
    // Verifica se o slug já foi armazenado
    sessionStorage.setItem('openedSlug', slug);
    sessionStorage.setItem('notificationOpenedModal', 'false');
  };

  const handleRowClick = (record) => {
    switch (record.notificationType) {
      case 'payment_order':
      case 'created_order':
        // Redirecionar para a página de pedidos
        if (record.notificationDetail.onOrderId) {
          saveNotificationSlug(record.notificationDetail.onOrderId)
        } 

        window.location.href = "/order";

        if(!record.hasRead){
          handleMarkAsRead(record._id)
        }
      break;
  
      case 'question_on_product':
        // Redirecionar para a página do produto com o ID do produto
        if (record.notificationDetail.onProductSlug) {
          saveNotificationSlug(record.notificationDetail.onProductSlug)
        } 

        window.location.href = "/manage-products";
        if(!record.hasRead){
          handleMarkAsRead(record._id)
        }
      break;
  
      case 'answer_on_product':
        // Redirecionar para a página relacionada à resposta no produto
        window.location.href = "/product";
        break;
  
      case 'review':
        // Redirecionar para uma página de avaliações ou produto
        window.location.href = "/reviews";
        break;
  
      default:
        // Redirecionar para uma rota padrão, se necessário
        window.location.href = "/";
        break;
    }
  };
  
  const handleMarkAsRead = (id) => {
    // Marca a notificação como lida
    readNotification(id);
    message.success('Notificação marcada como lida');
  };

  const columns = [
    {
      title: 'Tipo',
      dataIndex: 'notificationType',
      render: (type, record) => {
        const types = {
          question_on_product: {
            label: 'Pergunta sobre Produto',
            icon: <i className="text-primary mr-2 fas fa-question-circle"></i>,
          },
          created_order: {
            label: 'Pedido Realizado',
            icon: <i className="text-success mr-2 fas fa-shopping-cart"></i>,
          },
          payment_order: {
            label: 'Pagamento do Pedido',
            icon: <i className="align-middle mr-2 fas fa-fw fa-dollar-sign text-success"></i>,
          },
          answer_on_product: {
            label: 'Resposta sobre Produto',
            icon: <i className="text-info mr-2 fas fa-reply"></i>,
          },
          review: {
            label: 'Avaliação',
            icon: <i className="text-warning mr-2 fas fa-star"></i>,
          },
        };
    
        const notificationType = types[type] || { label: 'Desconhecido', icon: <i className="text-muted mr-2 fas fa-question"></i> };
    
        return (
          <div style={{ display: 'flex', alignItems: 'center', cursor:'pointer' }} onClick={() => handleRowClick(record)}>
            {notificationType.icon}
            <span>{notificationType.label}</span>
          </div>
        );
      },
    },
    {
      title: 'Notificação',
      dataIndex: 'notification',
      render: (text, record) => (
        <div style={{maxWidth: '400px',  cursor:'pointer'}} onClick={() => handleRowClick(record)}>
          {renderNotificationContent(record)}
        </div>
      ),
    },
    {
      title: 'Notificação Lida',
      dataIndex: 'hasRead',
      render: (hasRead, record) => (
        <div style={{cursor:'pointer'}} onClick={() => handleRowClick(record)}>
          <span>{hasRead ? 'Lida' : 'Não Lida'}</span>
        </div>
      ),
      filterDropdown: () => (
        <div style={{ padding: 8 }}>
          <Select
            defaultValue="all"
            style={{ width: 150 }}
            onChange={value => setFilter(value)}
          >
            <Option value="all">Todas</Option>
            <Option value="read">Lidas</Option>
            <Option value="unread">Não Lidas</Option>
          </Select>
        </div>
      ),
      filterIcon: () => <span>🔍</span>,
    },
    {
      title: 'Data',
      dataIndex: 'date',
      render: (date) => (
        <span>{moment(date).format('DD/MM/YYYY HH:mm')}</span>
      ),
      // Filtro de data na coluna
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <RangePicker
            value={selectedKeys[0] ? [moment(selectedKeys[0]), moment(selectedKeys[1])] : []}
            onChange={(dates) => {
              setSelectedKeys(dates ? [dates[0].format('YYYY-MM-DD'), dates[1].format('YYYY-MM-DD')] : []);
            }}
            style={{ width: 250 }}
          />
          <div style={{ marginTop: 8 }}>
            <Button
              type="link"
              onClick={() => {
                confirm();
                setDateRange(selectedKeys[0] ? [moment(selectedKeys[0]), moment(selectedKeys[1])] : []);
              }}
            >
              Filtrar
            </Button>
            <Button
              type="link"
              onClick={() => {
                clearFilters();
                setDateRange([]);
              }}
              style={{ marginLeft: 8 }}
            >
              Limpar
            </Button>
          </div>
        </div>
      ),
      filterIcon: () => <span>📅</span>,
    },
    {
      title: 'Ação',
      dataIndex: 'action',
      render: (text, record) => (
        <>
            {/* <button onClick={() => openProduct(product)} className="btn btn-info btn-sm"><i className="fas fa-eye"></i></button> */}
            {/* <button className="btn btn-info btn-sm"><i className="fas fa-eye"></i></button> */}
            <Popconfirm
                title="Tem certeza de que deseja marcar a notificação como lidar?"
                onConfirm={() => handleMarkAsRead(record._id)}
                okText="Sim"
                cancelText="Não"
            >
              <button className="btn btn-info btn-sm"><i className="fas fa-envelope-open p-1"></i> Marcar como lida</button>
            </Popconfirm>
        </>
      ),
    },
  ];

  // Configuração de paginação
  const pagination = {
    current: page,
    pageSize: pageSize,
    total: filteredNotifications.length,
    onChange: (page) => setPage(page),
  };

  return (
    <Layout>
      <Table
        columns={columns}
        dataSource={filteredNotifications.slice((page - 1) * pageSize, page * pageSize)}
        pagination={pagination}
        rowKey="_id"
        style={{ marginTop: 20 }}
      />
    </Layout>
  );
};

NotificationTable.propTypes = {
  socket: PropTypes.object,
  getNotifications: PropTypes.func.isRequired,
  adminNotification: PropTypes.object.isRequired,
  readNotification: PropTypes.func.isRequired,
  userProfile: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  socket: state.socket,
  adminNotification: state.notification,
  userProfile: state.auth,
});

export default connect(mapStateToProps, { getNotifications, readNotification })(NotificationTable);