import React from "react";
import { Switch, Route} from "react-router-dom";
import SuperAdminRoute from "./SuperAdminRoute";
import AdminRoute from './AdminRoute';

// UI components
import Home from "../components/pages/Home";
import Profile from "../components/pages/Profile";
import Product from '../components/pages/Product';
import AddProduct from '../components/pages/Product/AddProduct';
import EditProduct from '../components/pages/Product/EditProduct';
import Order from '../components/pages/Order';
import Admin from "../components/pages/SuperAdmin/Admin";
import User from "../components/pages/SuperAdmin/User";
import SProduct from "../components/pages/SuperAdmin/Product";
import AddAdmin from "../components/pages/SuperAdmin/AddAdmin";
import NotFound from '../components/pages/NotFound';
import Banner from '../components/pages/SuperAdmin/Banner';
import AddBanner from '../components/pages/SuperAdmin/AddBanner';
import Notification from '../components/pages/Notification';
import UnderMaintenance from '../components/pages/UnderMaintenance';
import QuestionAnswer from '../components/pages/QuestionAnswer';
import Category from '../components/pages/Category';
import CategoryAdd from '../components/pages/Category/AddCategory';
import Brand from '../components/pages/Brand'
import AddBrand from '../components/pages/Brand/AddBrand';
import EditBrand from '../components/pages/Brand/EditBrand';

const MainRouter = () => {
  return (
    <Switch>
      {/* Rotas comuns */}
      <Route exact path="/" component={Home} />
      <Route exact path="/profile" component={Profile} />
      <Route exact path="/settings-privacy" component={UnderMaintenance} />
      <Route exact path="/help" component={UnderMaintenance} />
      <Route exact path="/notification" component={Notification} />
     
      {/* Rotas Admin */}
      <AdminRoute exact path="/add-product" component={AddProduct} />
      <AdminRoute exact path="/order" component={Order} />
      <AdminRoute exact path="/edit-product/:slug?" component={EditProduct} />
      <AdminRoute exact path="/manage-products" component={Product} />
      <AdminRoute exact path="/question-answer" component={QuestionAnswer} />

      {/* Rotas Superadmin */}
      <SuperAdminRoute exact path="/superadmin/manage-products" component={SProduct} />
      <SuperAdminRoute exact path="/superadmin/add-admin" component={AddAdmin} />
      <SuperAdminRoute exact path="/superadmin/manage-admins" component={Admin} />
      <SuperAdminRoute exact path="/superadmin/manage-users" component={User} />
      <SuperAdminRoute exact path="/superadmin/add-banner" component={AddBanner} />
      <SuperAdminRoute exact path="/superadmin/manage-banners" component={Banner} />
      <SuperAdminRoute exact path="/superadmin/add-category" component={CategoryAdd} />
      <SuperAdminRoute exact path="/superadmin/manage-category" component={Category} />
      <SuperAdminRoute exact path="/superadmin/add-brand" component={AddBrand} />
      <SuperAdminRoute exact path="/superadmin/edit-brand/:slug?" component={EditBrand} />
      <SuperAdminRoute exact path="/superadmin/manage-brands" component={Brand} />

      <Route path="*" component={NotFound} />
    </Switch>
  );
};

export default MainRouter;
