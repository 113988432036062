import React from 'react'
import Layout from '../../core/Layout'
import BrandList from './BrandList'

function index() {
  return (
    <Layout>
        <BrandList />
    </Layout>
  )
}

export default index
