import React from 'react'
import PropTypes from 'prop-types'
import { Tabs } from 'antd';
import Layout from "../../core/Layout";
import ProfileForm from './ProfileForm';
import BusinessForm from './BusinessForm'
import BankForm from './BankForm'
import WarehouseForm from './WarehouseForm'
import { connect } from 'react-redux';

const Profile = ({ user }) => {
  const tabsItems = [
    {
      key: "a",
      label: "Perfil",
      children: <ProfileForm />,
    },
    {
      key: "b",
      label: "Negócio",
      children: <BusinessForm user={user} />,
    },
    {
      key: "c",
      label: "Banco",
      children: <BankForm user={user} />,
    },
    {
      key: "d",
      label: "Armazém",
      children: <WarehouseForm user={user} />,
    },
  ];

  return (
    <Layout>
      <div className="row">
        <div className="col-12 col-lg-12">
          <div className="card">
            <div className="col-md-12">
              <Tabs defaultActiveKey="a" items={tabsItems} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

Profile.propTypes = {
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.adminProfile || {},
});

export default connect(mapStateToProps, {})(React.memo(Profile));